<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="instructorSelectorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel">Choose Instructor</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To select an instructor, tap on the instructor and then tap the Set Instructor button below.</div>

            <div class="container-fluid" >
              <div class="row">
                <div class="col-lg-12">
                  <div style="position: relative;" class="d-grid gap-2 d-md-block">
                    <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshInstructors" :disabled="refreshingInstructors"><span v-show="refreshingInstructors"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingInstructors">Refresh Instructors</span></button>
                    <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="instructors.length === 0 || instructors.length > 1">{{instructors.length}} Instructors</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{instructors.length}} Instructor</div>
                  </div>
                  <div style="margin-top:15px;" class="table-responsive-xxl">
                    <v-table
                        :data="instructors"
                        ref="selectInstructorsTable"
                        class="table"
                        selectionMode="single"
                        selectedClass="table-primary"
                        @stateChanged="selectInstructor($event.selectedRows)">
                      <template #head>
                        <tr>
                          <th style="width:5px;">&nbsp;</th>
                          <v-th sortKey="title">Name</v-th>
                          <v-th sortKey="description">Email</v-th>
                          <v-th sortKey="description">Credentials</v-th>
                          <v-th sortKey="description">Sea Miles</v-th>
                        </tr>
                      </template>
                      <template #body="{ rows }">
                        <tr v-show="rows.length == 0 || refreshingInstructors">
                          <td>&nbsp;</td>
                          <td colspan="4" style="text-align: center"><span v-if="refreshingInstructors"><fai icon="spinner" spin/> Refreshing instructors...</span><span v-else>Ahoy there, what no instructors?</span></td>
                        </tr>
                        <v-tr v-for="row in rows" :key="row.id" :row="row">
                          <td v-if="!refreshingInstructors" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="instructorId" v-bind:value="row.id" v-model="selectedInstructorIds" @click="toggleInstructorRow"></td>
                          <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.firstName}} {{row.lastName}}</td>
                          <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.email}}</td>
                          <td v-if="!refreshingInstructors" style="vertical-align: middle" v-html="row.credentials"></td>
                          <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.seaMiles}}</td>
                        </v-tr>
                      </template>
                    </v-table>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="setInstructor">Set Instructor</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal} from "bootstrap"
import api from "@/api/api"

export default {
  name: 'InstructorSelectorModal',
  emits: ['instructorSelected'],
  data() {
    return {
      instructors: this.$store.state.instructors ? this.$store.state.instructors : [],
      refreshingInstructors: false,
      selectedInstructorIds: [],
      validation: {
        instructor: undefined
      }
    }
  },
  mounted() {

  },
  methods: {
    activateModal() {
      this.instructorSelectorModal = new Modal(document.getElementById('instructorSelectorModal'))
      this.validation = {
        instructor: undefined
      }
      this.instructorSelectorModal.toggle()

      this.instructors = this.$store.state.instructors ? this.$store.state.instructors : []

      if ( this.$store.state.instructorsUpdated === false ) {
        // first time to load this screen so go grab them if there are any...
        this.doRefreshInstructors()
      } else {
        this.$refs.selectInstructorsTable.deselectAll()
      }
    },
    setInstructor() {
      // reset validation state
      let isFormValid = true
      this.validation = {
        instructor: undefined
      }

      // validate the forms entries!
      if ( this.selectedInstructorIds === undefined || this.selectedInstructorIds === null || this.selectedInstructorIds.length <= 0 ) {
        isFormValid = false
        this.validation.instructor = false
      } else {
        this.validation.instructor = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingSchool = false
        return
      }

      this.$emit('instructorSelected', this.selectedInstructorIds[0])
      this.instructorSelectorModal.hide()
    },
    selectInstructor(selectedRows) {
      window.document.activeElement.blur()
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedInstructorIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedInstructorIds.push(aRow.id)
        })
      } else {
        this.selectedInstructorIds = []
      }
    },
    toggleInstructorRow(event) {
      window.document.activeElement.blur()
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.instructors ) {
          let selectedInstructor = undefined
          this.instructors.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedInstructor = aResult
            }
          })
          if ( selectedInstructor ) {
            this.selectedInstructorIds.push(selectedInstructor.id)
            let selectedRows = []
            this.selectedInstructorIds.forEach(function(anId) {
              self.instructors.forEach(function(anInstructor) {
                if ( anInstructor.id == anId ) {
                  selectedRows.push(anInstructor)
                }
              })
            })
            this.$refs.selectInstructorsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedInstructorIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedInstructorIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.selectInstructorsTable.deselectAll()
        this.selectedInstructorIds.forEach(function(anId) {
          self.instructors.forEach(function(anInstructor) {
            if ( anInstructor.id == anId ) {
              selectedRows.push(anInstructor)
            }
          })
        })
        this.$refs.selectInstructorsTable.selectRows(selectedRows)
      }
    },
    refreshInstructors() {
      window.document.activeElement.blur()
      this.doRefreshInstructors()
    },
    doRefreshInstructors() {
      // get the current set of
      this.refreshingInstructors = true
      this.$refs.selectInstructorsTable.deselectAll()
      let self = this
      api.getInstructors().then(response => {
        if (response && response.instructors && response.instructors.length > 0 ) {
          // all good
          self.instructors = response.instructors
          this.$store.commit('setInstructors', response.instructors)
        } else {
          // I guess there is nothing!
          this.$store.commit('setInstructors', [])
          self.instructors = []
        }
        this.refreshingInstructors = false
      })
    }
  },
  computed: {
  },
  components: {
  }
}
</script>

<style>

</style>