<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="faqEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="faq.id">Edit</span><span v-else>Add</span> FAQ</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="faq.id">edit this</span><span v-else>add a new</span> FAQ, fill out the form and tap the <span v-if="faq.id">Save</span><span v-else>Add</span> button below.</div>

            <div class="container" v-if="updatingFaq">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while your FAQ is <span v-if="faq.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid mb-3" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an unknown error <span v-if="faq.id">updating</span><span v-else>adding</span> this FAQ. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container-fluid" v-if="!updatingFaq">

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="question" name="question" v-model="faq.question" v-bind:class="{'is-valid' : validation.question, 'is-invalid': validation.question == false}">
                        <label for="location">Question</label>
                        <div id="locationHelpBlock" class="form-text">What is the frequently asked question text?</div>
                        <div class="invalid-feedback">Please provide question text.</div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-floating mb-4">
                        <textarea class="form-control" id="answer" name="answer" v-model="faq.answer" v-bind:class="{'is-valid' : validation.answer, 'is-invalid': validation.answer == false}" style="height: 160px"></textarea>
                        <label for="location">Answer</label>
                        <div id="locationHelpBlock" class="form-text">What is the best answer for this question?</div>
                        <div class="invalid-feedback">Please provide answer text.</div>
                      </div>
                    </div>
                  </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="crupFaq"><span v-if="faq.id">Save</span><span v-else>Add</span> FAQ</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="faqEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">FAQ Successfully <span v-if="faq.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The FAQ has been <span v-if="faq.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'FAQEditorModal',
  emits: ['faqUpdated'],
  data() {
    return {
      user:  this.$store.state.admin,
      updatingFaq: false,
      faq: {
        question: undefined,
        answer: undefined
      },
      validation : {
        question: undefined,
        answer: undefined
      },
      errorUpdating: false
    }
  },
  mounted() {

  },
  methods: {
    activateModal(faqToEdit) {
      this.faqEditorModal = new Modal(document.getElementById('faqEditorModal'))
      let faqEditorEl = document.getElementById('faqEditorModal')
      let self = this
      self.$store.state.activeModal = self.faqEditorModal
      faqEditorEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
        self.$emit('modalClose')
      })
      self.faqEditorModal.toggle()

      if ( faqToEdit !== undefined && faqToEdit.id !== undefined ) {
        this.faq = faqToEdit
      } else {
        this.faq = {
          question: undefined,
          answer: undefined
        }

      }
      this.validation = {
        question: undefined,
        answer: undefined
      }
      this.errorUpdating = false
    },
    crupFaq() {
      // validation!
      this.updatingFaq = true
      this.errorUpdating = false
      // reset validation state
      let isFormValid = true
      this.validation = {
        question: undefined,
        answer: undefined
      }

      // validate the forms entries!
      if ( this.faq.question === undefined || this.faq.question.trim().length <= 0 ) {
        isFormValid = false
        this.validation.question = false
      } else {
        this.validation.question = true
      }
      if ( this.faq.answer === undefined || this.faq.answer.trim().length <= 0 ) {
        isFormValid = false
        this.validation.answer = false
      } else {
        this.validation.answer = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingFaq = false
        return
      }

      // send the validated input to the back-end....
      let theFAQ = {
        id: this.faq.id !== undefined ? this.faq.id : undefined,
        question:this.faq.question,
        answer:this.faq.answer
      }
      let self = this
      api.crupFaq(theFAQ).then(response => {
        if (response && response.faq !== undefined ) {
          // all good - show the Toaster and cleanup....
          let faqEditorToast = new Toast(document.getElementById('faqEditorToast'))
          faqEditorToast.show()
          // close the modal
          self.faqEditorModal.hide()
          self.$emit('faqUpdated',response.faq)
          self.updatingFaq = false
        } else {
          // unknown error - what to do?
          self.updatingFaq = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>