<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="calendar-day" /> Calendar Events</h4>
          <div class="charterRodeHelpText">The below events drive the overall scheduling widget on your website!</div>
        </div>
      </div>



      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addCalendarEvent">Add Event</button>
            <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedCalendarEventIds.length <= 0" @click="removeSelectedCalendarEvents">Remove Selected</button>
            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshCalendarEvents" :disabled="refreshingCalendarEvents"><span v-show="refreshingCalendarEvents"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingCalendarEvents">Refresh Events</span></button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="calendarEvents.length === 0 || calendarEvents.length > 1">{{calendarEvents.length}} Events</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{calendarEvents.length}} Event</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <v-date-picker mode="date" v-model="startDate" :popover="{ visibility: 'click' }" class="d-inline">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-floating">
                <input style="height: 45px!important;padding-top: 1rem!important;padding-left: 0.5rem!important;" type="text" class="form-control form-control-sm charterRodeBtn" id="sched-startDate" name="courseStartDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.startDate, 'is-invalid': validation.courseStartDate == false}">
                <label for="sched-startDate" style="padding:.5rem 0.5rem!important;">Start Date</label>
              </div>
            </template>
          </v-date-picker>
        </div>
        <div class="col-6">
          <v-date-picker mode="date" v-model="endDate" :popover="{ visibility: 'click' }" class="d-inline">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-floating">
                <input style="height: 45px!important;padding-top: 1rem!important;padding-left: 0.5rem!important;" type="text" class="form-control form-control-sm charterRodeBtn" id="sched-EndDate" name="courseStartDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.endDate, 'is-invalid': validation.courseStartDate == false}">
                <label for="sched-EndDate" style="padding:.5rem 0.5rem!important;">End Date</label>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <table class="table table-responsive table-sm table-hover">
              <thead>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <th>Event Date</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Fees</th>
                  <th style="width: 10px;white-space:nowrap;text-align: center;">Spots Avail.</th>
                  <th style="width: 10px;white-space:nowrap;text-align: center;">Back To Back</th>
                  <th style="width:50px;white-space: nowrap;">&nbsp;</th>
                </tr>
              </thead>
              <tr v-show="calendarEventSections.length == 0 || refreshingCalendarEvents">
                <td class="pt-3">&nbsp;</td>
                <td class="pt-3" colspan="7" style="text-align: center"><span v-if="refreshingCalendarEvents"><fai icon="spinner" spin/> Refreshing events...</span><span v-else>Ahoy there, what no events? <a class="charterRodeLink" @click="addCalendarEvent">Let's add your first event!</a></span></td>
              </tr>
              <tbody v-for="section in calendarEventSections" :key="section.id">
                <tr class="table-secondary">
                  <td v-if="!refreshingCalendarEvents">&nbsp;</td>
                  <td v-if="!refreshingCalendarEvents" colspan="7" class="fw-bold fs-5 text-center">{{section.label}} - <span v-if="section.events.length === 0 || section.events.length > 1">{{section.events.length}} events</span><span v-else>{{section.events.length}} event</span></td>
                </tr>
                <tr v-for="row in section.events" :key="row.id" :row="row" :class="setColorCodeForEvent(row)">
                  <td v-if="!refreshingCalendarEvents" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="calendarEventId" v-bind:value="row.id" v-model="selectedCalendarEventIds"></td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align: middle"><a class="appBtn" @click="editCalendarEvent(row)"><span v-html="showDates(row)"></span></a></td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align:middle"><span v-html="showType(row)"></span></td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align: middle"><span v-if="row.price">${{row.price}}</span><span v-else>--</span></td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align: middle"><span v-if="row.fees">${{row.fees}}</span><span v-else>--</span></td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align: middle;text-align: center;"><span v-if="row.fees">{{row.spotsAvailable}}</span><span v-else>--</span></td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align:middle;text-align: center;"><span v-html="showBackToType(row)"></span>&nbsp;</td>
                  <td v-if="!refreshingCalendarEvents" style="vertical-align:middle;text-align: right;"><a class="appBtn btn btn-outline-secondary btn-sm" @click="copyCalendarEvent(row)"><fai icon="copy" style="color:#666;" /></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="calendarEventsRemovedToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Removed</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected events have been removed.</div>
      </div>
    </div>
  </teleport>

  <calendarEventEditorModal
      v-on:calendarEventUpdated="calendarEventUpdated"
      ref="calendarEventEditorModal"></calendarEventEditorModal>

</template>

<script>
import api from "@/api/api"
import {Toast} from "bootstrap";

export default {
  name: 'CalendarEvents',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      calendarEvents: this.$store.state.calendarEvents ? this.$store.state.calendarEvents : [],
      calendarEventSections: this.$store.state.calendarEventSections ? this.$store.state.calendarEventSections : [],
      selectedCalendarEventIds: [],
      refreshingCalendarEvents: false,
      startDate: undefined,
      endDate: undefined,
      validation: {
        startDate: undefined,
        endDate: undefined
      },
      monthShortLabels: ["Jan","Feb","Mar","Apr","May","Jun","Jul", "Aug","Sep","Oct","Nov","Dec"]
    }
  },
  mounted() {
    // set default dates to 21 days ago through 90 days from today....
    if ( this.startDate === undefined || this.endDate === undefined ) {
      let now = new Date()
      let firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
      this.startDate = new Date(new Date().setDate(firstDay.getDate()))
      this.endDate = new Date(new Date().setDate(firstDay.getDate() + 181))
    }

    if ( this.$store.state.calendarEventsUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshCalendarEvents()
    }
  },
  methods: {
    addCalendarEvent() {
      this.$refs.calendarEventEditorModal.activateModal()
    },
    copyCalendarEvent(aCalendarEvent) {
      // deep clone
      let calenderEventToEdit = JSON.parse(JSON.stringify(aCalendarEvent))
      calenderEventToEdit.id = undefined
      calenderEventToEdit.createdAt = undefined
      calenderEventToEdit.schoolId = undefined
      calenderEventToEdit.updatedAt = undefined
      calenderEventToEdit.versionId = undefined
      calenderEventToEdit.isCopy = true
      this.$refs.calendarEventEditorModal.activateModal(calenderEventToEdit)
    },
    editCalendarEvent(aCalendarEvent) {
      // deep clone
      let calenderEventToEdit = JSON.parse(JSON.stringify(aCalendarEvent))
      this.$refs.calendarEventEditorModal.activateModal(calenderEventToEdit)
    },
    calendarEventUpdated(theCalendarEvent) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.calendarEvents.forEach(function(aCalendarEvent) {
        if ( theCalendarEvent.id === aCalendarEvent.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        this.refreshCalendarEvents()
      } else {
        self.calendarEvents.push(theCalendarEvent)
        // update our store
        self.$store.commit('setCalendarEvents', self.calendarEvents)
        // update pivot
        this.createPivot()
      }
    },
    selectCalendarEvent(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedCalendarEventIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedCalendarEventIds.push(aRow.id)
        })
      } else {
        this.selectedCalendarEventIds = []
      }
    },
    toggleCalendarEventRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.calendarEvents ) {
          let selectedCalendarEvent = undefined
          this.calendarEvents.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedCalendarEvent = aResult
            }
          })
          if ( selectedCalendarEvent ) {
            this.selectedCalendarEventIds.push(selectedCalendarEvent.id)
            let selectedRows = []
            this.selectedCalendarEventIds.forEach(function(anId) {
              self.calendarEvents.forEach(function(aCalendarEvent) {
                if ( aCalendarEvent.id == anId ) {
                  selectedRows.push(aCalendarEvent)
                }
              })
            })
            this.$refs.calendarEventsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedCalendarEventIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedCalendarEventIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.selectedCalendarEventIds.forEach(function(anId) {
          self.calendarEvents.forEach(function(aCalendarEvent) {
            if ( aCalendarEvent.id == anId ) {
              selectedRows.push(aCalendarEvent)
            }
          })
        })
        this.$refs.calendarEventsTable.selectRows(selectedRows)
      }
    },
    removeSelectedCalendarEvents() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedCalendarEventIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.calendarEvents.length; ++i) {
            if ( self.calendarEvents[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.calendarEvents.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeCalendarEvents(this.selectedCalendarEventIds).then(response => {
          if (response && response.status == 'ok') {
            // we just need to update the store now
            this.$store.commit('setCalendarEvents', self.calendarEvents)
            let calendarEventsRemovedToast = new Toast(document.getElementById('calendarEventsRemovedToast'))
            calendarEventsRemovedToast.show()
          }
        })

        // cleanup
        this.selectedCalendarEventIds = []
      }
    },
    refreshCalendarEvents() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingCalendarEvents = true
      let self = this

      // figure out our dates....
      let offset = this.startDate.getTimezoneOffset()
      let finalStartDate = new Date(this.startDate.getTime() - (offset*60*1000)).toISOString().split('T')[0]
      let finalEndDate = new Date(this.endDate.getTime() - (offset*60*1000)).toISOString().split('T')[0]

      api.getCalendarEvents(this.user.schoolId, finalStartDate, finalEndDate).then(response => {
        if (response && response.items && response.items.length > 0 ) {
          // all good
          self.calendarEvents = response.items
          this.$store.commit('setCalendarEvents', response.items)

          if ( self.calendarEvents ) {
            self.createPivot()
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setCalendarEvents', [])
          self.calendarEvents = []
        }
        this.refreshingCalendarEvents = false
      })
    },
    showDates(aRow) {
      let theDate = new Date(aRow.startDate + ' 12:00:00')
      let theMonthShrt = this.monthShortLabels[theDate.getMonth()]
      let theEndDate = undefined
      if ( aRow.backToBackWeeks === true ) {
        theEndDate = new Date(new Date(theDate).setDate(theDate.getDate() + 7))
      } else if ( aRow.backToBackDays === true ) {
        theEndDate = new Date(new Date(theDate).setDate(theDate.getDate() + 1))
      } else if ( aRow.singleDay ) {
        return theMonthShrt + ' ' + theDate.getDate() + ' (' + theDate.toLocaleString('en-us', {  weekday: 'short' })+')'
      }
      return theMonthShrt + ' ' + theDate.getDate() + '/' + theEndDate.getDate() + ' (' + theDate.toLocaleString('en-us', {  weekday: 'short' }) + '/' + theEndDate.toLocaleString('en-us', {  weekday: 'short' }) + ')'
    },
    showType(aRow) {
      if ( aRow.type === 'course' ) {
        return 'Course | '+ aRow.courseTitleShort
      } else if ( aRow.type === 'community' ) {
        return 'Community Sail'
      } else if ( aRow.type === 'al_departure' ) {
        return 'AL Departure'
      } else if ( aRow.type === 'al_return' ) {
        return 'AL Return'
      }
      return 'Unknown'
    },
    showBackToType(aRow) {
      if ( aRow.backToBackDays ) {
        return 'Day'
      } else if ( aRow.backToBackWeeks ) {
        return 'Week'
      } else if ( aRow.singleDay ) {
        return 'Single'
      }
      return 'Unknown'
    },
    setColorCodeForEvent(aRow) {
      if ( aRow.type === 'course' ) {
        if ( aRow.courseTitleShort === 'ASA 101' ) {
          return 'table-primary'
        } else if ( aRow.courseTitleShort === 'ASA 103' ) {
          return 'table-dark'
        } else if ( aRow.courseTitleShort === 'ASA 105' ) {
          return 'table-warning'
        }
      } else if ( aRow.type === 'community' ) {
        return 'table-success'
      } else if ( aRow.type === 'al_departure' ) {
        return 'table-info'
      } else if ( aRow.type === 'al_return' ) {
        return 'table-info'
      }
      return ''
    },
    createPivot() {
      // sort by date ascending....
      this.calendarEvents.sort(function(a,b) {
        let sortA = a.startDate
        let sortB = b.startDate
        if (sortA > sortB) return 1
        if (sortA < sortB) return -1
        return 0
      })

      // now roll through them and create the 'sections'
      let sections = []
      let monthLabels= ["January","February","March","April","May","June","July", "August","September","October","November","December"];

      for (let i = 0; i < this.calendarEvents.length; ++i) {
        let theEvt = this.calendarEvents[i]
        let evtYear = theEvt.startDate.split('-')[0]
        let evtMonth = theEvt.startDate.split('-')[1]

        let matchSection = undefined
        sections.forEach(function(aSec) {
          if ( aSec.year === evtYear && aSec.month === evtMonth ) {
            matchSection = aSec
          }
        })
        if ( matchSection && matchSection.events ) {
          matchSection.events.push(theEvt)
        } else {
          matchSection = {
            year: theEvt.startDate.split('-')[0],
            month: theEvt.startDate.split('-')[1],
            events: []
          }
          matchSection.label = monthLabels[matchSection.month-1] +  ' ' + matchSection.year
          matchSection.events.push(theEvt)
          sections.push(matchSection)
        }
      }

      this.calendarEventSections = sections
      this.$store.commit('setCalendarEventSections', this.calendarEventSections)

    }
  },
  computed: {

  },
  components: {

  }
}
</script>



<style>
.appBtn {
  text-decoration: none;
  color:inherit;
}
.appBtn:hover {
  cursor: pointer;
  text-decoration: none;
  color:inherit;
}

</style>