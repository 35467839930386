<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="book" /> Courses</h4>
          <div class="charterRodeHelpText">Setup and manage courses available in the student app. Tap the title of a course to update it.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addCourse">Add Course</button>
            <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedCourseIds.length <= 0" @click="removeSelectedCourses">Remove Selected</button>
            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshCourses" :disabled="refreshingCourses"><span v-show="refreshingCourses"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingCourses">Refresh Courses</span></button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="courses.length === 0 || courses.length > 1">{{courses.length}} Courses</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{courses.length}} Course</div>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <v-table
                :data="courses"
                ref="coursesTable"
                class="table"
                selectionMode="multiple"
                selectedClass="table-primary"
                @stateChanged="selectCourse($event.selectedRows)">
              <template #head>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <v-th sortKey="courseTitle">Title</v-th>
                  <v-th sortKey="courseTitle" class="text-end"># Tasks</v-th>
                  <v-th sortKey="courseTitle" class="text-end">Est. Precourse Work (hh:mm)</v-th>
                  <v-th sortKey="courseDuration" class="text-end">Duration (days)</v-th>
                  <v-th sortKey="includeInReminders" class="text-end">Reminders On</v-th>
                </tr>
              </template>
              <template #body="{ rows }">
                <tr v-show="rows.length == 0 || refreshingCourses">
                  <td>&nbsp;</td>
                  <td colspan="6" style="text-align: center"><span v-if="refreshingCourses"><fai icon="spinner" spin/> Refreshing courses...</span><span v-else>Ahoy there, what no courses? <a class="charterRodeLink" @click="addCourse">Let's add your first course!</a></span></td>
                </tr>
                <v-tr v-for="row in rows" :key="row.id" :row="row">
                  <td v-if="!refreshingCourses" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="courseId" v-bind:value="row.id" v-model="selectedCourseIds" @click="toggleCourseRow"></td>
                  <td v-if="!refreshingCourses"><a @click="editCourse(row)">{{row.courseTitle}}</a></td>
                  <td v-if="!refreshingCourses" class="text-end"><span v-if="row.tasks">{{row.tasks.length}}</span><span v-else>0</span></td>
                  <td v-if="!refreshingCourses" class="text-end" v-html="estimateCourseWork(row)"></td>
                  <td v-if="!refreshingCourses" class="text-end">{{row.courseDurationDays}}</td>
                  <td v-if="!refreshingCourses" class="text-end"><span v-if="row.includeInReminders == true">Yes</span><span v-else>No</span></td>
                </v-tr>
              </template>
            </v-table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="coursesRemovedToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Removed</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected courses have been removed.</div>
      </div>
    </div>
  </teleport>

  <courseEditorModal
      v-on:courseUpdated="courseUpdated"
      ref="courseEditorModal"></courseEditorModal>


</template>

<script>
import api from "@/api/api"
import {Toast} from "bootstrap";

export default {
  name: 'Courses',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      courses: this.$store.state.courses ? this.$store.state.courses : [],
      selectedCourseIds: [],
      refreshingCourses: false
    }
  },
  mounted() {
    if ( this.$store.state.coursesUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshCourses()
    }
  },
  methods: {
    addCourse() {
      this.$refs.courseEditorModal.activateModal()
    },
    editCourse(aCourse) {
      // deep clone
      let courseToEdit = JSON.parse(JSON.stringify(aCourse))
      this.$refs.courseEditorModal.activateModal(courseToEdit)
    },
    courseUpdated(theCourse) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.courses.forEach(function(aCourse) {
        if ( theCourse.id === aCourse.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        self.courses.splice(foundAt,1)
        self.courses.push(theCourse)
      } else {
        self.courses.push(theCourse)
      }
      // update our store
      self.$store.commit('setCourses', self.courses)
      // clear selections
      this.$refs.coursesTable.deselectAll()
    },
    selectCourse(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedCourseIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedCourseIds.push(aRow.id)
        })
      } else {
        this.selectedCourseIds = []
      }
    },
    toggleCourseRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.courses ) {
          let selectedCourse = undefined
          this.courses.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedCourse = aResult
            }
          })
          if ( selectedCourse ) {
            this.selectedCourseIds.push(selectedCourse.id)
            let selectedRows = []
            this.selectedCourseIds.forEach(function(anId) {
              self.courses.forEach(function(aCourse) {
                if ( aCourse.id == anId ) {
                  selectedRows.push(aCourse)
                }
              })
            })
            this.$refs.coursesTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedCourseIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedCourseIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.coursesTable.deselectAll()
        this.selectedCourseIds.forEach(function(anId) {
          self.courses.forEach(function(aCourse) {
            if ( aCourse.id == anId ) {
              selectedRows.push(aCourse)
            }
          })
        })
        this.$refs.coursesTable.selectRows(selectedRows)
      }
    },
    removeSelectedCourses() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedCourseIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.courses.length; ++i) {
            if ( self.courses[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.courses.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeCourses(this.selectedCourseIds).then(response => {
          if (response && response.status == 'ok') {
            // we just need to update the store now
            this.$store.commit('setCourses', self.courses)
            let coursesRemovedToast = new Toast(document.getElementById('coursesRemovedToast'))
            coursesRemovedToast.show()
          }
        })

        // cleanup
        this.selectedCourseIds = []
        this.$refs.coursesTable.deselectAll()
      }
    },
    refreshCourses() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingCourses = true
      this.$refs.coursesTable.deselectAll()
      let self = this
      api.getCourses().then(response => {
        if (response && response.courses && response.courses.length > 0 ) {
          // all good
          self.courses = response.courses
          this.$store.commit('setCourses', response.courses)

          if ( self.courses ) {
            // alphabetize them!
            self.courses.sort(function(a,b) {
              if (a.courseTitle.toLowerCase() > b.courseTitle.toLowerCase()) return 1
              if (a.courseTitle.toLowerCase() < b.courseTitle.toLowerCase()) return -1
              return 0
            })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setCourses', [])
          self.courses = []
        }
        this.refreshingCourses = false
      })
    },
    estimateCourseWork(row) {
      let estimatedMinutes = 0
      if ( row.tasks ) {
        row.tasks.forEach(function(aTask) {
          estimatedMinutes += parseInt(aTask.timeMinutes)
        })
      }

      let h = Math.floor(estimatedMinutes / 60);
      let m = estimatedMinutes % 60;
      h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
      m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
      return `${h}:${m}`;
    }
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>