<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="dateSelectorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel">Choose Date</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">Tap the field below to select a date. To confirm the date, tap the Set Date button below.</div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="mb-4">
                      <v-date-picker mode="date" v-model="theDate" :popover="{ visibility: 'click' }" :min-date="minDate" :max-date="maxDate">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="form-floating">
                            <input type="text" class="form-control charterRodeBtn" id="sreg-courseStartDate" name="courseStartDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.courseStartDate, 'is-invalid': validation.courseStartDate == false}">
                            <label for="courseStartDate">Date</label>
                            <div class="invalid-feedback">Please select a date.</div>
                          </div>
                        </template>
                      </v-date-picker>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="setDate">Set Date</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal} from "bootstrap"

export default {
  name: 'DateSelectorModal',
  emits: ['dateSelected'],
  data() {
    return {
      theDate: undefined,
      validation: {
        date: undefined
      }
    }
  },
  mounted() {

  },
  methods: {
    activateModal() {
      this.dateSelectorModal = new Modal(document.getElementById('dateSelectorModal'))
      let self = this
      self.dateSelectorModal.toggle()
      this.theDate = undefined
      this.validation = {
        date: undefined
      }
    },
    setDate() {
      // reset validation state
      let isFormValid = true
      this.validation = {
        date: undefined
      }

      // validate the forms entries!
      if ( this.theDate === undefined || this.theDate === null ) {
        isFormValid = false
        this.validation.date = false
      } else {
        this.validation.date = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingSchool = false
        return
      }

      // screwy stuff to handle timezone crap
      let offset = this.theDate.getTimezoneOffset()
      let finalDate = new Date(this.theDate.getTime() - (offset*60*1000)).toISOString().split('T')[0]
      this.$emit('dateSelected',finalDate)
      this.dateSelectorModal.hide()
    },
  },
  computed: {
    minDate() {
      const oneYearDayMillis = 365 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // set the min date allowed
      todayDate.setTime(todayDate.getTime() - oneYearDayMillis)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    },
    maxDate() {
      const oneYearDayMillis = 365 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // set the max date allowed
      todayDate.setTime(todayDate.getTime() + oneYearDayMillis)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    }
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>