<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="instructorEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="instructor.id">Edit</span><span v-else>Add</span> Instructor</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="instructor.id">edit this</span><span v-else>add a new</span> instructor, fill out the form and tap the <span v-if="instructor.id">Save</span><span v-else>Add</span> button below.</div>

            <div class="container" v-if="updatingInstructor">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while your instructor is <span v-if="instructor.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid mb-3" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an unknown error <span v-if="instructor.id">updating</span><span v-else>adding</span> this instructor. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container-fluid" v-if="!updatingInstructor">

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="firstName" name="firstName" v-model="instructor.firstName" v-bind:class="{'is-valid' : validation.firstName, 'is-invalid': validation.firstName == false}" autocomplete="one-time-code">
                        <label for="firstName">First Name</label>
                        <div class="invalid-feedback">Please provide first name.</div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="lastName" name="lastName" v-model="instructor.lastName" v-bind:class="{'is-valid' : validation.lastName, 'is-invalid': validation.lastName == false}" autocomplete="one-time-code">
                        <label for="lastName">Last Name</label>
                        <div class="invalid-feedback">Please provide last name.</div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="email" class="form-control" id="email" name="email" v-model="instructor.email" v-bind:class="{'is-valid' : validation.email, 'is-invalid': validation.email == false}" autocomplete="one-time-code">
                        <label for="email">Email</label>
                        <div class="invalid-feedback">Please provide email.</div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="phone" name="phone" v-model="instructor.phone" v-bind:class="{'is-valid' : validation.phone, 'is-invalid': validation.phone == false}" autocomplete="one-time-code">
                        <label for="phone">Phone</label>
                        <div class="invalid-feedback">Please provide phone.</div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <select class="form-control form-control-sm" id="uscgCaptain" v-model="instructor.isUSCGCaptain" name="uscgCaptain" v-bind:class="{'is-valid' : validation.isUSCGCaptain, 'is-invalid': validation.isUSCGCaptain == false}">
                          <option value="please_select" disabled selected>Please Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <label for="uscgCaptain">Licensed USCG Captain?</label>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="seaMiles" name="seaMiles" v-model="instructor.seaMiles" v-bind:class="{'is-valid' : validation.seaMiles, 'is-invalid': validation.seaMiles == false}" autocomplete="one-time-code">
                        <label for="seaMiles">Sea Miles</label>
                        <div id="seaMilesHelpBlock" class="form-text">Estimated nautical miles as skipper or crew. For example, 15,000+.</div>
                      </div>
                    </div>

                  </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="favoriteDrink" name="favoriteDrink" v-model="instructor.favoriteDrink" v-bind:class="{'is-valid' : validation.favoriteDrink, 'is-invalid': validation.favoriteDrink == false}" autocomplete="one-time-code">
                      <label for="seaMiles">Favorite Beverage</label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="favoriteQuote" name="favoriteQuote" v-model="instructor.favoriteQuote" v-bind:class="{'is-valid' : validation.favoriteQuote, 'is-invalid': validation.favoriteQuote == false}" autocomplete="one-time-code">
                      <label for="seaMiles">Favorite Quote</label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="sailingResumeId" name="sailingResumeId" v-model="instructor.sailingResumeId" v-bind:class="{'is-valid' : validation.sailingResumeId, 'is-invalid': validation.sailingResumeId == false}" autocomplete="one-time-code">
                      <label for="sailingResumeId">Sailing Resume Id</label>
                      <div id="sailingResumeIdHelpBlock" class="form-text">Instructor's Public Sailor Id from the <a href="https://www.charterrode.com/article/charter-rode-wallet" target="_blank">Sailing Resume Blockchain</a>.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="credentials" name="credentials" v-model="instructor.credentials" v-bind:class="{'is-valid' : validation.credentials, 'is-invalid': validation.credentials == false}" autocomplete="one-time-code">
                      <label for="seaMiles">Credentials</label>
                      <div id="credentialsHelpBlock" class="form-text">List of relevant credentials, such as ASA certifications, etc. For example, ASA: 101, 103, 104, 105, 106, 114, 201, 203, 204, 205, 206, 214.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="bio" name="bio" v-model="instructor.bio" v-bind:class="{'is-valid' : validation.bio, 'is-invalid': validation.bio == false}" style="height: 160px"></textarea>
                      <label for="bio">Bio</label>
                      <div id="bioHelpBlock" class="form-text">Two to three sentence background of instructor.</div>
                    </div>
                  </div>
                </div>

              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="crupInstructor"><span v-if="instructor.id">Save</span><span v-else>Add</span> Instructor</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="instructorEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Instructor Successfully <span v-if="instructor.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The instructor has been <span v-if="instructor.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'InstructorEditorModal',
  emits: ['instructorUpdated'],
  data() {
    return {
      user:  this.$store.state.admin,
      updatingInstructor: false,
      instructor: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        isUSCGCaptain: 'false',
        seaMiles: undefined,
        favoriteDrink: undefined,
        credentials: undefined,
        favoriteQuote: undefined,
        bio: 'Coming soon',
        sailingResumeId: undefined
      },
      validation : {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        isUSCGCaptain: undefined,
        seaMiles: undefined,
        favoriteDrink: undefined,
        credentials: undefined,
        favoriteQuote: undefined,
        bio: undefined,
        sailingResumeId: undefined
      },
      errorUpdating: false
    }
  },
  mounted() {

  },
  methods: {
    activateModal(instructorToEdit) {
      this.instructorEditorModal = new Modal(document.getElementById('instructorEditorModal'))
      let instructorEditorEl = document.getElementById('instructorEditorModal')
      let self = this
      self.$store.state.activeModal = self.instructorEditorModal
      instructorEditorEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
      })
      self.instructorEditorModal.toggle()

      if ( instructorToEdit !== undefined && instructorToEdit.id !== undefined ) {
        this.instructor = instructorToEdit
        this.instructor.isUSCGCaptain = this.instructor.isUSCGCaptain ? 'true' : 'false'
      } else {
        this.instructor = {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          phone: undefined,
          isUSCGCaptain: 'false',
          seaMiles: undefined,
          favoriteDrink: undefined,
          credentials: undefined,
          favoriteQuote: undefined,
          bio: 'Coming soon',
          sailingResumeId: undefined
        }
      }
      this.validation = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        isUSCGCaptain: undefined,
        seaMiles: undefined,
        favoriteDrink: undefined,
        credentials: undefined,
        favoriteQuote: undefined,
        bio: undefined,
        sailingResumeId: undefined
      }
      this.errorUpdating = false
    },
    crupInstructor() {
      // validation!
      this.updatingInstructor = true
      this.errorUpdating = false
      // reset validation state
      let isFormValid = true
      this.validation = {
        question: undefined,
        answer: undefined
      }

      // validate the forms entries!
      if ( this.instructor.firstName === undefined || this.instructor.firstName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.firstName = false
      } else {
        this.validation.firstName = true
      }
      if ( this.instructor.lastName === undefined || this.instructor.lastName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.lastName = false
      } else {
        this.validation.lastName = true
      }
      if ( this.instructor.email === undefined || this.instructor.email.trim().length <= 0 || !this.$validateEmail(this.instructor.email) ) {
        isFormValid = false
        this.validation.email = false
      } else {
        this.validation.email = true
      }
      if ( this.instructor.phone === undefined || this.instructor.phone.trim().length <= 0 ) {
        isFormValid = false
        this.validation.phone = false
      } else {
        this.validation.phone = true
      }
      if ( this.instructor.bio === undefined || this.instructor.bio.trim().length <= 0 ) {
        isFormValid = false
        this.validation.bio = false
      } else {
        this.validation.bio = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingInstructor = false
        return
      }

      // send the validated input to the back-end....
      let theInstructor = {
        id: this.instructor.id !== undefined ? this.instructor.id : undefined,
        firstName: this.instructor.firstName.trim(),
        lastName: this.instructor.lastName.trim(),
        email: this.instructor.email.trim(),
        phone: this.instructor.phone,
        isUSCGCaptain: this.instructor.isUSCGCaptain === "true",
        seaMiles: this.instructor.seaMiles ? this.instructor.seaMiles : undefined,
        favoriteDrink: this.instructor.favoriteDrink ? this.instructor.favoriteDrink : undefined,
        credentials: this.instructor.credentials ? this.instructor.credentials : undefined,
        favoriteQuote: this.instructor.favoriteQuote ? this.instructor.favoriteQuote : undefined,
        bio: this.instructor.bio ? this.instructor.bio : undefined,
        sailingResumeId: this.instructor.sailingResumeId ? this.instructor.sailingResumeId : undefined
      }
      let self = this
      api.crupInstructor(theInstructor).then(response => {
        if (response && response.instructor !== undefined ) {
          // all good - show the Toaster and cleanup....
          let instructorEditorToast = new Toast(document.getElementById('instructorEditorToast'))
          instructorEditorToast.show()
          // close the modal
          self.instructorEditorModal.hide()
          self.$emit('instructorUpdated',response.instructor)
          self.updatingInstructor = false
        } else {
          // unknown error - what to do?
          self.updatingInstructor = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>