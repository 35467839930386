import { createRouter, createWebHashHistory } from 'vue-router'

// the store
import store from '../store/store'
const theStore = store

// 'views' that are routes
import Root from '../views/Root.vue'
import Home from '../views/admin/Home.vue'
import Students from '../views/admin/Students.vue'
import Courses from '../views/admin/Courses.vue'
import Schedule from '../views/admin/Schedule.vue'
import CalendarEvents from '../views/admin/CalendarEvents.vue'
import Instructors from '../views/admin/Instructors.vue'
import FAQs from '../views/admin/FAQs.vue'
import Emails from '../views/admin/Emails.vue'
import Schools from '../views/admin/Schools.vue'
import Profile from '../views/admin/Profile.vue'
import SignOut from '../views/SignOut.vue'

const appSubDirectory = process.env.VUE_APP_CHARTER_RODE_APP_SUBDIRECTORY

// our routes
const routes = [
    { path: '/', component: Root, name: 'Root', meta: {requiresAuth: false}},
    { path: '/:magicId', component: Root, name: 'MagicLink', meta: {requiresAuth: false}},
    { path: '/sign-out', component: SignOut, name: 'SignOut', meta: {requiresAuth: false}},
    { path: '/home', component: Home, name: 'Home', meta: {requiresAuth: true}},
    { path: '/students', component: Students, name: 'Students', meta: {requiresAuth: true}},
    { path: '/schedule', component: Schedule, name: 'Schedule', meta: {requiresAuth: true}},
    { path: '/events', component: CalendarEvents, name: 'CalendarEvents', meta: {requiresAuth: true}},
    { path: '/courses', component: Courses, name: 'Courses', meta: {requiresAuth: true}},
    { path: '/instructors', component: Instructors, name: 'Instructors', meta: {requiresAuth: true}},
    { path: '/faqs', component: FAQs, name: 'FAQs', meta: {requiresAuth: true}},
    { path: '/emails', component: Emails, name: 'Emails', meta: {requiresAuth: true}},
    { path: '/schools', component: Schools, name: 'Schools', meta: {requiresAuth: true}},
    { path: '/profile', component: Profile, name: 'Profile', meta: {requiresAuth: true}}
]

const routerHistory = createWebHashHistory()
const router = createRouter({
    history: routerHistory,
    base: appSubDirectory,
    strict: true,
    routes: routes,
    scrollBehavior () {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({left:0, top:0})
            }, 10)
        })
    }
})

// // remove trailing slashes
router.beforeEach((to) => {
    if ( theStore.state.activeModal ) {
        theStore.state.activeModal.hide()
        theStore.state.activeModal = undefined
        return false
    }
    if ( window.location && window.location.pathname && window.location.pathname.length > 1 ) {
        // rewrite this to see if we can use it to lookup magicId
        window.location = window.location.origin + "/#/" + window.location.pathname.substring(1, window.location.pathname.length)
    } else {
        if ( to.name != 'Root' && to.name !='MagicLink' && to.name != 'Maintenance' ) {
            // are we setup and logged in?
            if ( store != undefined && store.state != undefined && store.state.loggedIn && store.state.admin ) {
                // next() // go to wherever I'm going
            } else {
                // fall back to gather data from server for this session if possible,
                // otherwise force a magic link login
                return {name: 'Root'}
            }
        }
    }
})

export default router