<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Welcome {{user.firstName}}!</h4>
        <div class="charterRodeHelpText">What would you like to do today? <span v-if="user && user.superAdmin">You are logged in as a super admin.</span></div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="calendar" />&nbsp;Schedule</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">View the upcoming schedule for classes. Assign instructors to classes.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/schedule">Go to Schedule</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="calendar-day" />&nbsp;Calendar</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Set the upcoming schedule for classes available to book on your website</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/events">Go to Calendar</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="graduation-cap" />&nbsp;Students</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Create and manage students. Register and view student detailed information.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/students">Go to Students</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="chalkboard-teacher" />&nbsp;Instructors</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Create and manage instructors. Set their bio, sailing resume, and credentials.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/instructors">Go to Instructors</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai :icon="[ 'far', 'question-circle']"></fai>&nbsp;FAQs</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Setup and manage frequently asked questions.<br>&nbsp;</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/faqs">Go to FAQs</router-link>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="user-circle" />&nbsp;Profile</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Update your basic profile information.<br>&nbsp;</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/profile">Edit Profile</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="life-ring" />&nbsp;Support & Feedback</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Drop us a line <a href="mailto:support@charterrode.com?subject=SailEd Admin App Help">via email</a> with your questions or feedback!</p>
            <div class="d-grid gap-2">
              <a class="btn btn-outline-primary" href="mailto:support@charterrode.com?subject=SailEd Admin App Help">Email Us</a>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-4" v-if="user && user.superAdmin">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="envelope" />&nbsp;Emails</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Manage email templates for welcome, reminder, and custom emails on set schedules.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary " to="/emails">Go to Emails</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4" v-if="user && user.superAdmin">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="book" />&nbsp;Courses</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Create and manage courses. Setup and manage tasks to be completed by students.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/courses">Go to Courses</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">



      <div class="col-lg-4" v-if="user && user.superAdmin">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title home-header"><fai icon="school" />&nbsp;Schools</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Create and manage schools.<br>&nbsp;</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/schools">Go to Schools</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>


    </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {}
    }
  },
  mounted() {

  },
  methods: {

  },
  components: {

  }
}
</script>

<style>

.home-header {
  color: #666;
}
.class-start-header {
  font-weight: bold;
  font-size: 1.1em;
}
.course-title {
  font-weight: bold;
  font-size: 1.25em;
}

.card-subtitle {
  font-size: .85em!important;
}

.card-body {
  padding: .5em;
}

.taskLinkButton {
  font-size: 20px!important;
}
</style>