<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="studentRegistrationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel">Student Registration</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
<!--            <div class="pb-4 charterRodeHelpText">Help text goes here.</div>-->

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container" v-if="sendingRegistration">
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while registration is processed ...</div>
                  </div>
                </div>
              </div>

              <div class="container-fluid mb-3" v-if="errorUpdating">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="tx-submit-spinner">
                      <div class="alert alert-danger" role="alert">
                        There was an <span v-if="errorMessage === undefined">unknown </span>error during processing of this registration.
                        <div v-if="errorMessage" class="fw-bold">Error: {{errorMessage}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!sendingRegistration">
                <div class="card">
                  <div class="card-header">
                    <strong>Step 1: Student Info</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="pb-3 charterRodeHelpText">Provide student information.</div>
                        </div>
                        <div class="row">
                          <div class="col-lg-3">
                            <div class="form-floating mb-4">
                              <input type="text" class="form-control" id="sreg-firstName" name="firstName" v-model="student.firstName" v-bind:class="{'is-valid' : validation.firstName, 'is-invalid': validation.firstName == false}" autocomplete="one-time-code" :disabled="lockStudent">
                              <label for="sreg-firstName">First Name</label>
                              <div class="invalid-feedback">Please provide student first name.</div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-floating mb-4">
                              <input type="text" class="form-control" id="sreg-lastName" name="lastName" v-model="student.lastName" v-bind:class="{'is-valid' : validation.lastName, 'is-invalid': validation.lastName == false}" autocomplete="one-time-code" :disabled="lockStudent">
                              <label for="sreg-lastName">Last Name</label>
                              <div class="invalid-feedback">Please provide student last name.</div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-floating mb-4">
                              <input type="email" class="form-control" id="sreg-email" name="email" v-model="student.email" v-bind:class="{'is-valid' : validation.email, 'is-invalid': validation.email == false}" autocomplete="one-time-code" :disabled="lockStudent">
                              <label for="sreg-email">Email</label>
                              <div class="invalid-feedback">Please provide student email.</div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-floating mb-4">
                              <input type="text" class="form-control" id="sreg-phone" name="phone" v-model="student.phone" v-bind:class="{'is-valid' : validation.phone, 'is-invalid': validation.phone == false}" autocomplete="one-time-code" :disabled="lockStudent">
                              <label for="sreg-phone">Phone</label>
                              <div class="invalid-feedback">Please provide student phone.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-2">
                  <div class="card-header">
                    <strong>Step 2: Choose Course</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="pb-3 charterRodeHelpText">Choose a course that this student will be registered for.</div>
                          <div style="position: relative;" class="d-grid gap-2 d-md-block">
                            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshCourses" :disabled="refreshingCourses"><span v-show="refreshingCourses"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingCourses">Refresh Courses</span></button>
                            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="courses.length === 0 || courses.length > 1">{{courses.length}} Courses</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{courses.length}} Course</div>
                          </div>

                          <div style="margin-top:15px;" class="table-responsive-xxl">
                            <v-table
                                :data="courses"
                                ref="sRegCoursesTable"
                                class="table"
                                selectionMode="single"
                                selectedClass="table-primary"
                                @stateChanged="selectCourse($event.selectedRows)">
                              <template #head>
                                <tr>
                                  <th style="width:5px;">&nbsp;</th>
                                  <v-th sortKey="title">Title</v-th>
                                  <v-th sortKey="description" class="text-end"># Tasks</v-th>
                                  <v-th sortKey="description" class="text-end">Est. Precourse Work (hh:mm)</v-th>
                                  <v-th sortKey="description" class="text-end">Duration (days)</v-th>
                                </tr>
                              </template>
                              <template #body="{ rows }">
                                <tr v-show="rows.length == 0 || refreshingCourses">
                                  <td>&nbsp;</td>
                                  <td colspan="5" style="text-align: center"><span v-if="refreshingCourses"><fai icon="spinner" spin/> Refreshing courses...</span><span v-else>Ahoy there, what no courses?</span></td>
                                </tr>
                                <v-tr v-for="row in rows" :key="row.id" :row="row">
                                  <td v-if="!refreshingCourses" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="courseId" v-bind:value="row.id" v-model="selectedCourseIds" @click="toggleCourseRow"></td>
                                  <td v-if="!refreshingCourses">{{row.courseTitle}}</td>
                                  <td v-if="!refreshingCourses" class="text-end">{{row.tasks.length}}</td>
                                  <td v-if="!refreshingCourses" class="text-end" v-html="estimateCourseWork(row)"></td>
                                  <td v-if="!refreshingCourses" class="text-end">{{row.courseDurationDays}}</td>
                                </v-tr>
                              </template>
                            </v-table>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-2">
                  <div class="card-header">
                    <strong>Step 3: Choose Instructor</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="pb-3 charterRodeHelpText">Optionally assign an instructor for this course.</div>
                          <div style="position: relative;" class="d-grid gap-2 d-md-block">
                            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshInstructors" :disabled="refreshingInstructors"><span v-show="refreshingInstructors"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingInstructors">Refresh Instructors</span></button>
                            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="instructors.length === 0 || instructors.length > 1">{{instructors.length}} Instructors</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{instructors.length}} Instructor</div>
                          </div>
                          <div style="margin-top:15px;" class="table-responsive-xxl">
                            <v-table
                                :data="instructors"
                                ref="sRegInstructorsTable"
                                class="table"
                                selectionMode="single"
                                selectedClass="table-primary"
                                @stateChanged="selectInstructor($event.selectedRows)">
                              <template #head>
                                <tr>
                                  <th style="width:5px;">&nbsp;</th>
                                  <v-th sortKey="title">Name</v-th>
                                  <v-th sortKey="description">Email</v-th>
                                  <v-th sortKey="description">Credentials</v-th>
                                  <v-th sortKey="description">Sea Miles</v-th>
                                </tr>
                              </template>
                              <template #body="{ rows }">
                                <tr v-show="rows.length == 0 || refreshingInstructors">
                                  <td>&nbsp;</td>
                                  <td colspan="4" style="text-align: center"><span v-if="refreshingInstructors"><fai icon="spinner" spin/> Refreshing instructors...</span><span v-else>Ahoy there, what no instructors?</span></td>
                                </tr>
                                <v-tr v-for="row in rows" :key="row.id" :row="row">
                                  <td v-if="!refreshingInstructors" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="instructorId" v-bind:value="row.id" v-model="selectedInstructorIds" @click="toggleInstructorRow"></td>
                                  <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.firstName}} {{row.lastName}}</td>
                                  <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.email}}</td>
                                  <td v-if="!refreshingInstructors" style="vertical-align: middle" v-html="row.credentials"></td>
                                  <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.seaMiles}}</td>
                                </v-tr>
                              </template>
                            </v-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-2">
                  <div class="card-header">
                    <strong>Step 4: Start Date</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="pb-3 charterRodeHelpText">Choose the start date of the in-person portion of the course for this student.</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="mb-4">
                            <v-date-picker mode="date" v-model="courseStartDate" :popover="{ visibility: 'click' }" :min-date="minDateForCourse" :max-date="maxDateForCourse">
                              <template v-slot="{ inputValue, inputEvents }">
                                <div class="form-floating">
                                  <input type="text" class="form-control charterRodeBtn" id="sreg-courseStartDate" name="courseStartDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.courseStartDate, 'is-invalid': validation.courseStartDate == false}">
                                  <label for="courseStartDate">Start Date</label>
                                  <div class="invalid-feedback">Please provide start date.</div>
                                </div>
                              </template>
                            </v-date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" :class="{'disabled': !formComplete}" @click="completeRegistration">Register Student</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="studentRegistrationToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Registration Successful!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The student has been registered.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'StudentRegistrationModal',
  emits: ['studentRegistered'],
  data() {
    return {
      user:  this.$store.state.admin,
      courses: this.$store.state.courses ? this.$store.state.courses : [],
      refreshingCourses: false,
      selectedCourseIds: [],
      instructors: this.$store.state.instructors ? this.$store.state.instructors : [],
      refreshingInstructors: false,
      selectedInstructorIds: [],
      sendingRegistration: false,
      errorUpdating: false,
      errorMessage: undefined,
      courseStartDate: undefined,
      lockStudent: false,
      student: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined
      },
      validation: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        courseStartDate: undefined
      }
    }
  },
  mounted() {

  },
  methods: {
    activateModal(options) {
      this.studentRegistrationModal = new Modal(document.getElementById('studentRegistrationModal'))
      let studentRegistrationEl = document.getElementById('studentRegistrationModal')
      let self = this
      self.$store.state.activeModal = self.studentRegistrationModal
      studentRegistrationEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
      })
      self.studentRegistrationModal.toggle()

      // make the user pick a date
      this.courseStartDate = undefined
      this.lockStudent = false
      if ( options !== undefined && options.student !== undefined) {
        // set the student
        this.student = {
          firstName: options.student.firstName,
          lastName: options.student.lastName,
          email: options.student.email,
          phone: options.student.phone
        }
        this.lockStudent = true
        // TODO: figure out various start states here...
      } else {
        // possibly new registration?
        this.student=  {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          phone: undefined
        }
        // default form focus to first name
        window.setTimeout(function() {
          document.getElementById("sreg-firstName").focus()
        }, 750)

      }
      this.validation = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        courseStartDate: undefined
      }
      this.errorMessage = undefined
      this.errorUpdating = false

      this.courses = this.$store.state.courses ? this.$store.state.courses : []
      this.instructors = this.$store.state.instructors ? this.$store.state.instructors : []

      // do we need to fetch the available courses?
      if ( this.$store.state.coursesUpdated == false ) {
        // first time to load this screen so go grab them if there are any...
        this.doRefreshCourses()
      } else {
        this.$refs.sRegCoursesTable.deselectAll()
      }

      if ( this.$store.state.instructorsUpdated == false ) {
        // first time to load this screen so go grab them if there are any...
        this.doRefreshInstructors()
      } else {
        this.$refs.sRegInstructorsTable.deselectAll()
      }
    },
    completeRegistration() {
      // validation!
      this.sendingRegistration = true
      this.errorUpdating = false
      this.errorMessage = undefined
      // reset validation state
      let isFormValid = true
      this.validation = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        courseStartDate: undefined
      }

      // validate the forms entries!
      if ( this.student.firstName === undefined || this.student.firstName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.firstName = false
      } else {
        this.validation.firstName = true
      }
      if ( this.student.lastName === undefined || this.student.lastName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.lastName = false
      } else {
        this.validation.lastName = true
      }
      if ( this.student.email === undefined || this.student.email.trim().length <= 0 || !this.$validateEmail(this.student.email) ) {
        isFormValid = false
        this.validation.email = false
      } else {
        this.validation.email = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.sendingRegistration = false
        return
      }

      // screwy stuff to handle timezone crap
      let offset = this.courseStartDate.getTimezoneOffset()
      let finalDate = new Date(this.courseStartDate.getTime() - (offset*60*1000)).toISOString().split('T')[0]

      // send the validated input to the back-end....
      let theRegistration = {
        firstName:this.student.firstName,
        lastName:this.student.lastName,
        email:this.student.email,
        phone:this.student.phone,
        courseId: this.selectedCourseIds[0],
        instructorId: this.selectedInstructorIds && this.selectedInstructorIds.length > 0 ? this.selectedInstructorIds[0]: undefined,
        startDate: finalDate
      }
      let self = this
      api.enrollStudent(theRegistration).then(response => {
        if (response && response.status == 'ok' ) {
          // all good - show the Toaster and cleanup....
          let studentRegistrationToast = new Toast(document.getElementById('studentRegistrationToast'))
          studentRegistrationToast.show()
          // close the modal
          self.studentRegistrationModal.hide()
          self.sendingRegistration = false
          self.validation = {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            courseStartDate: undefined
          }
          // did we get a student object back to send to StudentEditorModal
          if ( response && response.student !== undefined ) {
            self.$emit('studentRegistered',response.student)
          }
        } else {
          if ( response ) {
            self.errorMessage = response
          }
          // unknown error - what to do?
          self.sendingRegistration = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
    refreshCourses() {
      window.document.activeElement.blur()
      this.doRefreshCourses()
    },
    doRefreshCourses() {
      // get the current set of
      this.refreshingCourses = true
      this.$refs.sRegCoursesTable.deselectAll()
      let self = this
      api.getCourses().then(response => {
        if (response && response.courses && response.courses.length > 0 ) {
          // all good
          self.courses = response.courses
          this.$store.commit('setCourses', response.courses)

          if ( self.courses ) {
            // alphabetize them!
            self.courses.sort(function(a,b) {
              if (a.courseTitle.toLowerCase() > b.courseTitle.toLowerCase()) return 1
              if (a.courseTitle.toLowerCase() < b.courseTitle.toLowerCase()) return -1
              return 0
            })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setCourses', [])
          self.courses = []
        }
        this.refreshingCourses = false
      })
    },
    selectCourse(selectedRows) {
      window.document.activeElement.blur()
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedCourseIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedCourseIds.push(aRow.id)
        })
      } else {
        this.selectedCourseIds = []
      }
    },
    toggleCourseRow(event) {
      window.document.activeElement.blur()
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.courses ) {
          let selectedCourse = undefined
          this.courses.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedCourse = aResult
            }
          })
          if ( selectedCourse ) {
            this.selectedCourseIds.push(selectedCourse.id)
            let selectedRows = []
            this.selectedCourseIds.forEach(function(anId) {
              self.courses.forEach(function(aCourse) {
                if ( aCourse.id == anId ) {
                  selectedRows.push(aCourse)
                }
              })
            })
            this.$refs.sRegCoursesTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedCourseIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedCourseIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.sRegCoursesTable.deselectAll()
        this.selectedCourseIds.forEach(function(anId) {
          self.courses.forEach(function(aCourse) {
            if ( aCourse.id == anId ) {
              selectedRows.push(aCourse)
            }
          })
        })
        this.$refs.sRegCoursesTable.selectRows(selectedRows)
      }
    },
    estimateCourseWork(row) {
      let estimatedMinutes = 0
      if ( row.tasks ) {
        row.tasks.forEach(function(aTask) {
          estimatedMinutes += parseInt(aTask.timeMinutes)
        })
      }

      let h = Math.floor(estimatedMinutes / 60);
      let m = estimatedMinutes % 60;
      h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
      m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
      return `${h}:${m}`;
    },
    selectInstructor(selectedRows) {
      window.document.activeElement.blur()
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedInstructorIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedInstructorIds.push(aRow.id)
        })
      } else {
        this.selectedInstructorIds = []
      }
    },
    toggleInstructorRow(event) {
      window.document.activeElement.blur()
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.instructors ) {
          let selectedInstructor = undefined
          this.instructors.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedInstructor = aResult
            }
          })
          if ( selectedInstructor ) {
            this.selectedInstructorIds.push(selectedInstructor.id)
            let selectedRows = []
            this.selectedInstructorIds.forEach(function(anId) {
              self.instructors.forEach(function(anInstructor) {
                if ( anInstructor.id == anId ) {
                  selectedRows.push(anInstructor)
                }
              })
            })
            this.$refs.sRegInstructorsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedInstructorIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedInstructorIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.sRegInstructorsTable.deselectAll()
        this.selectedInstructorIds.forEach(function(anId) {
          self.instructors.forEach(function(anInstructor) {
            if ( anInstructor.id == anId ) {
              selectedRows.push(anInstructor)
            }
          })
        })
        this.$refs.sRegInstructorsTable.selectRows(selectedRows)
      }
    },
    refreshInstructors() {
      window.document.activeElement.blur()
      this.doRefreshInstructors()
    },
    doRefreshInstructors() {
      // get the current set of
      this.refreshingInstructors = true
      this.$refs.sRegInstructorsTable.deselectAll()
      let self = this
      api.getInstructors().then(response => {
        if (response && response.instructors && response.instructors.length > 0 ) {
          // all good
          self.instructors = response.instructors
          this.$store.commit('setInstructors', response.instructors)

          if ( self.instructors ) {
            // alphabetize them!
            // self.instructors.sort(function(a,b) {
            //   let aName = a.firstName.toLowerCase()+'-'+a.lastName.toLowerCase()
            //   let bName = b.firstName.toLowerCase()+'-'+b.lastName.toLowerCase()
            //   if (aName > bName) return 1
            //   if (aName < bName) return -1
            //   return 0
            // })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setInstructors', [])
          self.instructors = []
        }
        this.refreshingInstructors = false
      })
    }
  },
  computed: {
    formComplete() {
      if ( this.selectedCourseIds.length > 0 &&
           this.student.firstName !== undefined && this.student.firstName.length > 0 &&
          this.student.lastName !== undefined && this.student.lastName.length > 0 &&
          this.student.email !== undefined && this.student.email.length > 0 &&
          this.courseStartDate !== undefined && this.courseStartDate !== null ) {
        return true
      }
      return false
    },
    minDateForCourse() {
      const oneYearDayMillis = 365 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // set the min date allowed
      todayDate.setTime(todayDate.getTime() - oneYearDayMillis)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    },
    maxDateForCourse() {
      const oneYearDayMillis = 365 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // set the max date allowed
      todayDate.setTime(todayDate.getTime() + oneYearDayMillis)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    }
  },
  components: {

  }
}
</script>

<style>
</style>