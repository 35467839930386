<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="emailEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="email.id">Edit</span><span v-else>Add</span> Email</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="email.id">edit this</span><span v-else>add a new</span> email, fill out the form and tap the <span v-if="email.id">Save</span><span v-else>Add</span> button below.</div>

            <div class="container" v-if="updatingEmail">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while your email is <span v-if="email.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an <span v-if="errorMessage === undefined">unknown </span>error <span v-if="email.id">updating</span><span v-else>adding</span> this email.
                      <div v-if="errorMessage" class="fw-bold">Error: {{errorMessage}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container-fluid" v-if="!updatingEmail">

                <div class="row">

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="offsetDays" name="offsetDays" v-model="email.offsetDays" v-bind:class="{'is-valid' : validation.offsetDays, 'is-invalid': validation.offsetDays == false}">
                      <label for="offsetDays">Offset Days</label>
                      <div class="invalid-feedback">Please provide the number of days for the offset between 0 and 90 days.</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="offsetType" v-model="email.offsetType" name="offsetType">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="before">Before Start Date</option>
                        <option value="after">After Start Date</option>
                      </select>
                      <label for="offsetType">Offset Type</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="enabledForAllCourses" v-model="email.enabledForAllCourses" name="enabledForAllCourses">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label for="enabledForAllCourses">Enabled for All Courses?</label>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="subject" name="subject" v-model="email.subject" v-bind:class="{'is-valid' : validation.subject, 'is-invalid': validation.subject == false}">
                      <label for="subject">Subject</label>
                      <div class="invalid-feedback">Please provide the email subject.</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="emailType" v-model="email.emailType" name="emailType">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="student">Student</option>
                        <option value="instructor">Instructor</option>
                      </select>
                      <label for="emailType">Email Type</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="active" v-model="email.active" name="active">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label for="active">Active?</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="body" name="body" v-model="email.body" v-bind:class="{'is-valid' : validation.body, 'is-invalid': validation.body == false}" style="height: 550px"></textarea>
                      <label for="body">Body</label>
                      <div class="invalid-feedback">Please provide the email body.</div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="crupEmail" :disabled="updatingEmail"><span v-if="email.id">Save</span><span v-else>Add</span> Email</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="emailEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Email Successfully <span v-if="email.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The email has been <span v-if="email.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'EmailEditorModal',
  emits: ['emailUpdated'],
  data() {
    return {
      user:  this.$store.state.admin,
      updatingEmail: false,
      email: {
        subject: undefined,
        enabledForAllCourses: 'true',
        emailType: 'student',
        active: 'true',
        offsetType: 'before',
        offsetDays: undefined
      },
      validation : {
        subject: undefined,
        offsetDays: undefined,
        body: undefined
      },
      errorUpdating: false,
      errorMessage: undefined
    }
  },
  mounted() {

  },
  methods: {
    activateModal(emailToEdit) {
      this.emailEditorModal = new Modal(document.getElementById('emailEditorModal'))
      let self = this
      self.emailEditorModal.toggle()

      if ( emailToEdit !== undefined && emailToEdit.id !== undefined ) {
        this.email = emailToEdit
        this.email.enabledForAllCourses = this.email.enabledForAllCourses ? 'true' : 'false'
        this.email.active = this.email.active ? 'true' : 'false'
      } else {
        this.email = {
          subject: undefined,
          enabledForAllCourses: 'true',
          active: 'true',
          emailType: 'student',
          offsetType: 'before',
          offsetDays: undefined
        }

      }
      this.validation = {
        subject: undefined,
        offsetDays: undefined
      }
      this.updatingEmail = false
      this.errorUpdating = false
      this.errorMessage = undefined
    },
    crupEmail() {
      // validation!
      this.updatingEmail = true
      this.errorUpdating = false
      this.errorMessage = undefined
      // reset validation state
      let isFormValid = true
      this.validation = {
        subject: undefined,
        offsetDays: undefined
      }

      // validate the forms entries!
      if ( this.email.subject === undefined || this.email.subject.trim().length <= 0 ) {
        isFormValid = false
        this.validation.subject = false
      } else {
        this.validation.subject = true
      }

      if ( this.email.body === undefined || this.email.body.trim().length <= 0 ) {
        isFormValid = false
        this.validation.body = false
      } else {
        this.validation.body = true
      }

      if ( this.email.offsetDays === undefined || parseInt(this.email.offsetDays) < 0 || parseInt(this.email.offsetDays) > 90 ) {
        isFormValid = false
        this.validation.offsetDays = false
      } else {
        this.validation.offsetDays = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingEmail = false
        return
      }

      // send the validated input to the back-end....
      let theEmail = {
        id: this.email.id !== undefined ? this.email.id : undefined,
        subject: this.email.subject,
        body: this.email.body,
        enabledForAllCourses: this.email.enabledForAllCourses === "true",
        emailType: this.email.emailType,
        active: this.email.active === "true",
        offsetDays: this.email.offsetDays,
        offsetType: this.email.offsetType
      }
      let self = this
      api.crupEmail(theEmail).then(response => {
        if (response && response.email !== undefined ) {
          // all good - show the Toaster and cleanup....
          let emailEditorToast = new Toast(document.getElementById('emailEditorToast'))
          emailEditorToast.show()
          // close the modal
          self.emailEditorModal.hide()
          self.$emit('emailUpdated',response.email)
          self.updatingEmail = false
        } else {
          // unknown error - what to do?
          // error
          if ( response ) {
            self.errorMessage = response
          }
          self.updatingEmail = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>