<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="graduation-cap" /> Students</h4>
          <div class="charterRodeHelpText">View and manage students with access to the student app. To find a student use the search options below and tap the 'Find Students' button. Tap the name of the student to view the details for a specific student. To register a student for a course, tap the Register Student button.</div>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-3">
          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="firstName" name="firstName" v-model="firstName" autocomplete="one-time-code" v-on:keyup.enter="findStudents()">
            <label for="firstName">First Name starts with</label>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="lastName" name="lastName" v-model="lastName" autocomplete="one-time-code" v-on:keyup.enter="findStudents()">
            <label for="lastName">Last Name starts with</label>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-floating mb-4">
            <input type="email" class="form-control" id="email" name="email" v-model="email" autocomplete="one-time-code" v-on:keyup.enter="findStudents()">
            <label for="email">Email starts with</label>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="asaId" name="asaId" v-model="asaId" autocomplete="one-time-code" v-on:keyup.enter="findStudents()">
            <label for="asaId">ASA # starts with</label>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
          <div>
            <div class="d-grid gap-2">
              <button id="updateProfileButton" class="btn btn-outline-primary text-uppercase" :class="{'disabled': searchingForStudents}" @click="findStudents()"><span v-if="searchingForStudents"><fai icon="spinner" spin/> Finding Students ...</span><span v-else>Find Students</span></button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" :class="{'disabled': searchingForStudents}" @click="registerStudent()">Register Student</button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="students.length === 0 || students.length > 1">{{students.length}} matching students</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{students.length}} matching student</div>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <v-table
                :data="students"
                ref="studentsTable"
                class="table"
                selectionMode="multiple"
                selectedClass="table-primary">
              <template #head>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <v-th sortKey="lastName">Name</v-th>
                  <v-th sortKey="email">Email</v-th>
                  <v-th sortKey="asaMemberId">ASA #</v-th>
                </tr>
              </template>
              <template #body="{ rows }">
                <tr v-show="rows.length == 0 || searchingForStudents">
                  <td>&nbsp;</td>
                  <td colspan="4" style="text-align: center"><span v-if="searchingForStudents"><fai icon="spinner" spin/> Looking for students in the abyss...</span><span v-else>Ahoy there, no matching students found.</span></td>
                </tr>
                <tr v-for="row in rows" :key="row.id">
                  <td v-if="!searchingForStudents" style="vertical-align: middle">&nbsp;</td>
                  <td v-if="!searchingForStudents" style="vertical-align: middle"><span class="charterRodeBtn" @click="editStudent(row)">{{row.firstName}} {{row.lastName}}</span></td>
                  <td v-if="!searchingForStudents" style="vertical-align: middle">{{row.email}}</td>
                  <td v-if="!searchingForStudents" style="vertical-align: middle">{{row.asaMemberId}}</td>
                </tr>
              </template>
            </v-table>
          </div>
        </div>
      </div>



<!--      <div class="row">-->
<!--        <div class="col-12">-->

<!--          Register student for class<br>-->
<!--          Recent registrations - last 30, 60, 90 days<br>-->

<!--      GOES TO SCHEDULES-->
<!--          Upcoming classes - next 10, 15, 30, 90 days<br>-->
<!--          Assign Instructor to upcoming class<br>-->

<!--        </div>-->
<!--      </div>-->

    </div>
  </div>


  <studentEditorModal
      v-on:studentUpdated="studentUpdated"
      v-on:registerStudent="registerStudentForCourse"
      ref="studentEditorModal"></studentEditorModal>

  <studentRegistrationModal
      v-on:studentRegistered="studentRegistered"
      ref="studentRegistrationModal"></studentRegistrationModal>


</template>

<script>

import api from "@/api/api"

export default {
  name: 'Students',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      students: this.$store.state.students ? this.$store.state.students : [],
      firstName: this.$store.state.firstName ? this.$store.state.firstName : undefined,
      lastName: this.$store.state.lastName ? this.$store.state.lastName : undefined,
      email: this.$store.state.email ? this.$store.state.email : undefined,
      asaId: this.$store.state.asaId ? this.$store.state.asaId : undefined,
      searchingForStudents: false,
      selectedStudentsIds: []
    }
  },
  mounted() {
    // default form focus to first name
    window.setTimeout(function() {
      document.getElementById("firstName").focus()
    }, 500)
  },
  methods: {
    findStudents() {
      window.document.activeElement.blur()
      let self = this
      self.searchingForStudents = true
      api.findStudents(self.firstName, self.lastName, self.email, self.asaId).then(response => {
        if (response && response.students !== undefined ) {
          self.students = response.students
          self.$store.commit('setStudents', {students: self.students, firstName: self.firstName, lastName: self.lastName, email: self.email, asaId:self.asaId})
        } else {
          self.students = []
          self.$store.commit('setStudents', {students: self.students, firstName: self.firstName, lastName: self.lastName, email: self.email, asaId:self.asaId})
        }
        self.searchingForStudents = false
      })
    },
    registerStudent() {
      // this is a multi-step modal:
      // 1. Add new student or update if email address already exists
      // 2. Optionally select course to enroll into
      // 3. If set #3, then set a start date for the course to confirm the enrollment
      this.$refs.studentRegistrationModal.activateModal()
    },
    editStudent(aStudent) {
      // deep clone
      let studentToEdit = JSON.parse(JSON.stringify(aStudent))
      this.$refs.studentEditorModal.activateModal(studentToEdit)
    },
    registerStudentForCourse(aStudent) {
      this.$refs.studentRegistrationModal.activateModal({student:aStudent})
    },
    studentUpdated(theStudent) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.students.forEach(function(aStudent) {
        if ( theStudent.id === aStudent.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        self.students.splice(foundAt,1)
        self.students.push(theStudent)
      } else {
        self.students.push(theStudent)
      }
      // update our store
      self.$store.commit('setStudents', {students: self.students, firstName: self.firstName, lastName: self.lastName, email: self.email, asaId:self.asaId})
    },
    studentRegistered(theStudent) {
      console.log('studentRegistered:'+theStudent.id)
      // select this one to edit...
      this.editStudent(theStudent)
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>
