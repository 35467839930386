import { createApp } from 'vue'
import store from './store/store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@popperjs/core';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAnchor, faCalendarDay, faCopy, faPlus, faMinus,faHome, faBook, faLifeRing, faCalendar, faEnvelope, faTimesCircle, faSchool, faInfoCircle, faCog, faChalkboardTeacher, faGraduationCap, faUserCircle, faSignInAlt, faSignOutAlt, faUsers, faListAlt, faSpinner, faShip, faExclamationTriangle, faExternalLinkAlt, faCheck, faCertificate, faKey, faDollarSign, faHistory, faWallet, faLongArrowAltLeft, faLock, faBan, faPassport, faShare} from '@fortawesome/free-solid-svg-icons'
import { faEye, faAddressCard, faCreditCard, faQuestionCircle, faFileAlt, faCheckCircle, faHourglass, faCircle, faArrowAltCircleRight, faArrowAltCircleLeft, faFile} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import router from './router/router'
import VCalendar from 'v-calendar'
import SmartTable from 'vuejs-smart-table'

// default view that uses the router
import App from './App.vue'

// import VueGtag from "vue-gtag"


// setup the app with the default view
const app = createApp(App)

// Install the store instance as a plugin
app.use(store)

// setup font awesome - this is dumb but i get it, each and every
// icon has to be added to our custom list otherwise the dam download would be too big
// for nearly 12,000 icons....
library.add(faAnchor)
library.add(faCalendar)
library.add(faCalendarDay)
library.add(faGraduationCap)
library.add(faSchool)
library.add(faCopy)
library.add(faEnvelope)
library.add(faLifeRing)
library.add(faTimesCircle)
library.add(faCog)
library.add(faChalkboardTeacher)
library.add(faInfoCircle)
library.add(faHome)
library.add(faHourglass)
library.add(faQuestionCircle)
library.add(faCircle)
library.add(faCheckCircle)
library.add(faPlus)
library.add(faMinus)
library.add(faBook)
library.add(faFile)
library.add(faFileAlt)
library.add(faUserCircle)
library.add(faSignOutAlt)
library.add(faSignInAlt)
library.add(faListAlt)
library.add(faUsers)
library.add(faSpinner)
library.add(faShip)
library.add(faExclamationTriangle)
library.add(faExternalLinkAlt)
library.add(faEye)
library.add(faAddressCard)
library.add(faCreditCard)
library.add(faCheck)
library.add(faCertificate)
library.add(faArrowAltCircleRight)
library.add(faArrowAltCircleLeft)
library.add(faKey)
library.add(faDollarSign)
library.add(faHistory)
library.add(faWallet)
library.add(faLongArrowAltLeft)
library.add(faLock)
library.add(faBan)
library.add(faPassport)
library.add(faShare)
app.component("fai", FontAwesomeIcon)

// set the router
app.use(router)

// set VCalendar
app.use(VCalendar, {})

// set SmartTable
app.use(SmartTable)

const EmailValidator = {
    install(app) {
        // configure the app
        app.config.globalProperties.$validateEmail = (anEmail) => {
            let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (anEmail.match(validRegex)) {
                return true
            } else {
                return false
            }
        }
    }
}
app.use(EmailValidator)


// set VueGtag
if ( process.env.VUE_APP_ENABLE_GOOGLE_ANALYTICS ) {
    // app.use(VueGtag, {config: {id: "G-49P4VHWB00"}}, router)
}

// this is a bit of magic to include all components in the /base/ component directory...
const requireComponent = require.context (
    // The relative path of the 'base' components folder
    './components/base',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )

    app.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    )
})
window.charterRodeApp = app
// mount up!
app.mount('#app')
