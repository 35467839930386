import axios from "axios";

const apiBase = process.env.VUE_APP_SOFLOW_API_ENDPOINT

function processErrorResponse(errorDetail) {
    console.log(errorDetail)
    // alert(errorDetail.message)
}

let api = {

    getInfoForMagicId: async (magicId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/magicLink/' + magicId
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getInfoForAdmin: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin'
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    sendMagicLink: async (anEmail) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/sendMagicLink/' + anEmail
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getFaqs: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/faqs'
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    removeFaqs: async (faqIds) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/faqs/remove',
                data: {
                    faqIds: faqIds
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupFaq: async (aFaq) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/faqs/crup',
                data: {
                    faq: aFaq
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getCourses: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/courses'
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    removeCourses: async (courseIds) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/courses/remove',
                data: {
                    courseIds: courseIds
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupCourse: async (aCourse) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/courses/crup',
                data: {
                    course: aCourse
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getEmails: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/emails'
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    removeEmails: async (emailIds) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/emails/remove',
                data: {
                    emailIds: emailIds
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupEmail: async (anEmail) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/emails/crup',
                data: {
                    email: anEmail
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getInstructors: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/instructors'
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    removeInstructors: async (instructorIds) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/instructors/remove',
                data: {
                    instructorIds: instructorIds
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupInstructor: async (anInstructor) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/instructors/crup',
                data: {
                    instructor: anInstructor
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getSchools: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/schools'
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    removeSchools: async (schoolIds) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/schools/remove',
                data: {
                    schoolIds: schoolIds
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupSchool: async (aSchool) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/schools/crup',
                data: {
                    school: aSchool
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    setStickySchool: async (schoolId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/schools/sticky/'+schoolId
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    signOut: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/signOut',
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    updateAdminProfile: async (data) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/update',
                data: data
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    findStudents: async (firstName, lastName, email, asaId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/find',
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    asaId: asaId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupStudent: async (aStudent) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/crup',
                data: {
                    student: aStudent
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getStudentEnrollments: async (studentId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/admin/students/enrollments/' + studentId
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    resetEnrollment: async (aStudentId, anEnrollmentId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/resetEnrollment',
                data: {
                    studentId: aStudentId,
                    enrollmentId: anEnrollmentId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },
    dropEnrollment: async (aStudentId, anEnrollmentId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/dropEnrollment',
                data: {
                    studentId: aStudentId,
                    enrollmentId: anEnrollmentId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },
    setSailingResumeIdFor: async (anEnrollmentId, aSailingResumeId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/setSailingResumeId',
                data: {
                    sailingResumeId: aSailingResumeId,
                    enrollmentId: anEnrollmentId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },
    sendReminderEmail: async (anEnrollmentId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/sendReminderEmail',
                data: {
                    enrollmentId: anEnrollmentId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },
    sendWelcomeEmail: async (anEnrollmentId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/sendWelcomeEmail',
                data: {
                    enrollmentId: anEnrollmentId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },
    sendInstructorReassignedEmail: async (anEnrollmentId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/sendInstructorReassignedEmail',
                data: {
                    enrollmentId: anEnrollmentId
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },
    setScoreForEnrollment: async (aStudentId, anEnrollmentId, aScore) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/setScore',
                data: {
                    studentId: aStudentId,
                    enrollmentId: anEnrollmentId,
                    score: aScore
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    setStartDateForEnrollment: async (aStudentId, anEnrollmentId, aNewDate) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/setStartDate',
                data: {
                    studentId: aStudentId,
                    enrollmentId: anEnrollmentId,
                    startDate: aNewDate
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    setInstructorForEnrollment: async (aStudentId, anEnrollmentId, anInstructorId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/setInstructor',
                data: {
                    studentId: aStudentId,
                    enrollmentId: anEnrollmentId,
                    instructorId: anInstructorId
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    enrollStudent: async (data) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/students/enroll',
                data: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    courseId: data.courseId,
                    instructorId: data.instructorId,
                    startDate: data.startDate
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
                return response.data.errorDetail
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getScheduleItems: async (schoolId, startDate, endDate) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/courses/schedules',
                data: {
                    schoolId: schoolId,
                    startDate: startDate,
                    endDate: endDate
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    getCalendarEvents: async (schoolId, startDate, endDate) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/events',
                data: {
                    schoolId: schoolId,
                    startDate: startDate,
                    endDate: endDate
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    removeCalendarEvents: async (calendarEventIds) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/events/remove',
                data: {
                    calendarEventIds: calendarEventIds
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    },

    crupCalendarEvent: async (aCalendarEvent) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/admin/events/crup',
                data: {
                    calendarEvent: aCalendarEvent
                }
            })
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
        return null
    }
}
export default api