<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="chalkboard-teacher" /> Instructors</h4>
          <div class="charterRodeHelpText">Setup and manage instructors available to teach your classes. Tap the name of an instructor to update their info.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addInstructor">Add Instructor</button>
            <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedInstructorIds.length <= 0" @click="removeSelectedInstructors">Remove Selected</button>
            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshInstructors" :disabled="refreshingInstructors"><span v-show="refreshingInstructors"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingInstructors">Refresh Instructors</span></button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="instructors.length === 0 || instructors.length > 1">{{instructors.length}} Instructors</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{instructors.length}} Instructor</div>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <v-table
                :data="instructors"
                ref="instructorsTable"
                class="table"
                selectionMode="multiple"
                selectedClass="table-primary"
                @stateChanged="selectInstructor($event.selectedRows)">
              <template #head>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <v-th sortKey="lastName">Name</v-th>
                  <v-th sortKey="email">Email</v-th>
                  <v-th sortKey="credentials">Credentials</v-th>
                  <v-th sortKey="seaMiles">Sea Miles</v-th>
                  <v-th sortKey="displayOrder">Display Order</v-th>
                </tr>
              </template>
              <template #body="{ rows }">
                <tr v-show="rows.length == 0 || refreshingInstructors">
                  <td>&nbsp;</td>
                  <td colspan="6" style="text-align: center"><span v-if="refreshingInstructors"><fai icon="spinner" spin/> Refreshing instructors...</span><span v-else>Ahoy there, what no instructors? <a class="charterRodeLink" @click="addInstructor">Let's add your first instructor!</a></span></td>
                </tr>
                <v-tr v-for="row in rows" :key="row.id" :row="row">
                  <td v-if="!refreshingInstructors" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="instructorId" v-bind:value="row.id" v-model="selectedInstructorIds" @click="toggleInstructorRow"></td>
                  <td v-if="!refreshingInstructors" style="vertical-align: middle"><a @click="editInstructor(row)">{{row.firstName}} {{row.lastName}}</a></td>
                  <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.email}}</td>
                  <td v-if="!refreshingInstructors" style="vertical-align: middle" v-html="row.credentials"></td>
                  <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.seaMiles}}</td>
                  <td v-if="!refreshingInstructors" style="vertical-align: middle">{{row.displayOrder}}</td>
                </v-tr>
              </template>
            </v-table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="instructorsRemovedToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Removed</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected instructors have been removed.</div>
      </div>
    </div>
  </teleport>

  <instructorEditorModal
      v-on:instructorUpdated="instructorUpdated"
      ref="instructorEditorModal"></instructorEditorModal>

</template>

<script>
import api from "@/api/api"
import {Toast} from "bootstrap";

export default {
  name: 'Instructors',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      instructors: this.$store.state.instructors ? this.$store.state.instructors : [],
      selectedInstructorIds: [],
      refreshingInstructors: false
    }
  },
  mounted() {
    if ( this.$store.state.instructorsUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshInstructors()
    }
  },
  methods: {
    addInstructor() {
      this.$refs.instructorEditorModal.activateModal()
    },
    editInstructor(anInstructor) {
      // deep clone
      let instructorToEdit = JSON.parse(JSON.stringify(anInstructor))
      this.$refs.instructorEditorModal.activateModal(instructorToEdit)
    },
    instructorUpdated(theInstructor) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.instructors.forEach(function(anInstructor) {
        if ( theInstructor.id === anInstructor.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        self.instructors.splice(foundAt,1)
        self.instructors.push(theInstructor)
      } else {
        self.instructors.push(theInstructor)
      }
      // update our store
      self.$store.commit('setInstructors', self.instructors)
      // clear selections
      this.$refs.instructorsTable.deselectAll()
    },
    selectInstructor(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedInstructorIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedInstructorIds.push(aRow.id)
        })
      } else {
        this.selectedInstructorIds = []
      }
    },
    toggleInstructorRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.instructors ) {
          let selectedInstructor = undefined
          this.instructors.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedInstructor = aResult
            }
          })
          if ( selectedInstructor ) {
            this.selectedInstructorIds.push(selectedInstructor.id)
            let selectedRows = []
            this.selectedInstructorIds.forEach(function(anId) {
              self.instructors.forEach(function(anInstructor) {
                if ( anInstructor.id == anId ) {
                  selectedRows.push(anInstructor)
                }
              })
            })
            this.$refs.instructorsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedInstructorIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedInstructorIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.instructorsTable.deselectAll()
        this.selectedInstructorIds.forEach(function(anId) {
          self.instructors.forEach(function(anInstructor) {
            if ( anInstructor.id == anId ) {
              selectedRows.push(anInstructor)
            }
          })
        })
        this.$refs.instructorsTable.selectRows(selectedRows)
      }
    },
    removeSelectedInstructors() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedInstructorIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.instructors.length; ++i) {
            if ( self.instructors[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.instructors.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeInstructors(this.selectedInstructorIds).then(response => {
          if (response && response.status == 'ok') {
            // we just need to update the store now
            this.$store.commit('setInstructors', self.instructors)
            let instructorsRemovedToast = new Toast(document.getElementById('instructorsRemovedToast'))
            instructorsRemovedToast.show()
          }
        })

        // cleanup
        this.selectedInstructorIds = []
        this.$refs.instructorsTable.deselectAll()
      }
    },
    refreshInstructors() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingInstructors = true
      this.$refs.instructorsTable.deselectAll()
      let self = this
      api.getInstructors().then(response => {
        if (response && response.instructors && response.instructors.length > 0 ) {
          // all good
          self.instructors = response.instructors
          this.$store.commit('setInstructors', response.instructors)

          if ( self.instructors ) {
            // alphabetize them!
            // self.instructors.sort(function(a,b) {
            //   let aName = a.firstName.toLowerCase()+'-'+a.lastName.toLowerCase()
            //   let bName = b.firstName.toLowerCase()+'-'+b.lastName.toLowerCase()
            //   if (aName > bName) return 1
            //   if (aName < bName) return -1
            //   return 0
            // })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setInstructors', [])
          self.instructors = []
        }
        this.refreshingInstructors = false
      })
    }
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>