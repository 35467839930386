
import { createStore } from 'vuex'

// Create a new store instance
// critical components are backed by the localStorage so that if someone
// clicks the refresh screen then the right thing happens and we don't
// loose all of the info
const store = createStore({
    state () {
        return {
            loggedIn: localStorage.getItem('loggedIn') ? localStorage.getItem('loggedIn') === 'true' : false,
            courses: undefined,
            coursesUpdated: false,
            emails: undefined,
            emailsUpdated: false,
            instructors: undefined,
            instructorsUpdated: false,
            faqs: undefined,
            faqsUpdated: false,
            schools: undefined,
            schoolsUpdated: false,
            scheduleItems: undefined,
            scheduleStartDate: undefined,
            scheduleEndDate: undefined,
            scheduleItemsUpdated: false,
            students: undefined,
            studentsUpdated: false,
            calendarEvents: undefined,
            calendarEventSections: undefined,
            calendarEventsUpdated: false,
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            asaId: undefined,
            isMaintenance: false,
            admin: undefined
        }
    },
    mutations: {
        setAdminInfo(state, adminInfo) {
            if ( adminInfo ) {
                state.loggedIn = true
                if ( adminInfo.admin ) {
                    state.admin = adminInfo.admin
                    localStorage.setItem('loggedIn', 'true')
                }
            }
        },
        setCourses(state, courses) {
            if ( courses && courses.length > 0 ) {
                state.courses = courses
            } else {
                state.courses = undefined
            }
            state.coursesUpdated = true
        },
        setEmails(state, emails) {
            if ( emails && emails.length > 0 ) {
                state.emails = emails
            } else {
                state.emails = undefined
            }
            state.emailsUpdated = true
        },
        setInstructors(state, instructors) {
            if ( instructors && instructors.length > 0 ) {
                state.instructors = instructors
            } else {
                state.instructors = undefined
            }
            state.instructorsUpdated = true
        },
        setFaqs(state, faqs) {
            if ( faqs && faqs.length > 0 ) {
                state.faqs = faqs
            } else {
                state.faqs = undefined
            }
            state.faqsUpdated = true
        },
        setSchools(state, schools) {
            if ( schools && schools.length > 0 ) {
                state.schools = schools
            } else {
                state.schools = undefined
            }
            state.schoolsUpdated = true
        },
        setScheduleItems(state, params) {
            if ( params && params.scheduleItems && params.scheduleItems.length > 0 ) {
                state.scheduleItems = params.scheduleItems
            } else {
                state.scheduleItems = undefined
            }
            state.scheduleItemsUpdated = true
            if ( params ) {
                state.scheduleStartDate = params.startDate
                state.scheduleEndDate = params.endDate
            }
        },
        setCalendarEvents(state, calendarEvents) {
            if ( calendarEvents && calendarEvents.length > 0 ) {
                state.calendarEvents = calendarEvents
            } else {
                state.calendarEvents = undefined
            }
            state.calendarEventsUpdated = true
        },
        setCalendarEventSections(state, calendarEventSections) {
            if ( calendarEventSections && calendarEventSections.length > 0 ) {
                state.calendarEventSections = calendarEventSections
            } else {
                state.calendarEventSections = undefined
            }
        },
        setStickySchool(state, schoolId) {
            state.admin.schoolId = schoolId
            state.courses = undefined
            state.coursesUpdated = false
            state.emails = undefined
            state.emailsUpdated = false
            state.instructors = undefined
            state.instructorsUpdated = false
            state.faqs = undefined
            state.faqsUpdated = false
            state.students = undefined
            state.studentsUpdated = false
            state.calendarEvents = undefined
            state.calendarEventSections = undefined
            state.calendarEventsUpdated = false
            state.scheduleItems = undefined
            state.scheduleStartDate = undefined
            state.scheduleEndDate = undefined
            state.scheduleItemsUpdated = false
        },
        setStudents(state, data) {
            if ( data.students && data.students.length > 0 ) {
                state.students = data.students
            } else {
                state.students = undefined
            }
            state.studentsUpdated = true
            state.firstName = data.firstName
            state.lastName = data.lastName
            state.email = data.email
            state.asaId = data.asaId
        },
        clearCache(state) {
            state.courses = undefined
            state.coursesUpdated = false
            state.emails = undefined
            state.emailsUpdated = false
            state.instructors = undefined
            state.instructorsUpdated = false
            state.faqs = undefined
            state.faqsUpdated = false
            state.students = undefined
            state.studentsUpdated = false
            state.calendarEvents = undefined
            state.calendarEventSections = undefined
            state.calendarEventsUpdated = false
            state.scheduleItems = undefined
            state.scheduleStartDate = undefined
            state.scheduleEndDate = undefined
            state.scheduleItemsUpdated = false
            state.firstName = undefined
            state.lastName = undefined
            state.email = undefined
            state.asaId = undefined
        },
        clearAll(state) {
            state.loggedIn = false
            state.admin = undefined
            state.courses = undefined
            state.coursesUpdated = false
            state.emails = undefined
            state.emailsUpdated = false
            state.instructors = undefined
            state.instructorsUpdated = false
            state.faqs = undefined
            state.faqsUpdated = false
            state.schools = undefined
            state.schoolsUpdated = false
            state.students = undefined
            state.studentsUpdated = false
            state.calendarEvents = undefined
            state.calendarEventSections = undefined
            state.calendarEventsUpdated = false
            state.scheduleItems = undefined
            state.scheduleStartDate = undefined
            state.scheduleEndDate = undefined
            state.scheduleItemsUpdated = false
            state.firstName = undefined
            state.lastName = undefined
            state.email = undefined
            state.asaId = undefined
            localStorage.removeItem('loggedIn')
        }
    },
    getters: {

    }
})

export default store