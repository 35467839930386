<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai :icon="[ 'far', 'question-circle']"></fai> FAQs</h4>
          <div class="charterRodeHelpText">Setup and manage frequently asked questions displayed in the student app. Tap the question of a FAQ to update it.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addFAQ">Add FAQ</button>
            <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedFaqsIds.length <= 0" @click="removeSelectedFaqs">Remove Selected</button>
            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshFaqs" :disabled="refreshingFaqs"><span v-show="refreshingFaqs"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingFaqs">Refresh FAQs</span></button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="faqs.length === 0 || faqs.length > 1">{{faqs.length}} FAQs</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{faqs.length}} FAQ</div>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <v-table
                :data="faqs"
                ref="faqsTable"
                class="table"
                selectionMode="multiple"
                selectedClass="table-primary"
                @stateChanged="selectFaq($event.selectedRows)">
              <template #head>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <v-th sortKey="question">Question</v-th>
                  <v-th sortKey="answer">Answer</v-th>
                  <v-th sortKey="displayOrder" style="white-space: nowrap;">Order</v-th>
                </tr>
              </template>
              <template #body="{ rows }">
                <tr v-show="rows.length == 0 || refreshingFaqs">
                  <td>&nbsp;</td>
                  <td colspan="3" style="text-align: center"><span v-if="refreshingFaqs"><fai icon="spinner" spin/> Refreshing FAQs...</span><span v-else>Ahoy there, what no FAQs? <a class="charterRodeLink" @click="addFAQ">Let's add your first FAQ!</a></span></td>
                </tr>
                <v-tr v-for="row in rows" :key="row.id" :row="row">
                  <td v-if="!refreshingFaqs" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="faqId" v-bind:value="row.id" v-model="selectedFaqsIds" @click="toggleFaqRow"></td>
                  <td v-if="!refreshingFaqs" style="vertical-align: middle"><a @click="editFaq(row)">{{row.question}}</a></td>
                  <td v-if="!refreshingFaqs" style="vertical-align: middle" v-html="row.answer"></td>
                  <td v-if="!refreshingFaqs" style="vertical-align: middle">{{row.displayOrder}}</td>
                </v-tr>
              </template>
            </v-table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="faqsRemovedToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Removed</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected FAQs have been removed.</div>
      </div>
    </div>
  </teleport>

  <faqEditorModal
      v-on:faqUpdated="faqUpdated"
      ref="faqEditorModal"></faqEditorModal>

</template>

<script>
import api from "@/api/api"
import {Toast} from "bootstrap";

export default {
  name: 'FAQs',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      faqs: this.$store.state.faqs ? this.$store.state.faqs : [],
      selectedFaqsIds: [],
      refreshingFaqs: false
    }
  },
  mounted() {
    if ( this.$store.state.faqsUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshFaqs()
    }
  },
  methods: {
    addFAQ() {
      this.$refs.faqEditorModal.activateModal()
    },
    editFaq(anFaq) {
      // deep clone
      let faqToEdit = JSON.parse(JSON.stringify(anFaq))
      this.$refs.faqEditorModal.activateModal(faqToEdit)
    },
    faqUpdated(theFAQ) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.faqs.forEach(function(aFaq) {
        if ( theFAQ.id === aFaq.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        self.faqs.splice(foundAt,1)
        self.faqs.push(theFAQ)
      } else {
        self.faqs.push(theFAQ)
      }
      if ( self.faqs ) {
        // alphabetize them!
        self.faqs.sort(function(a,b) {
          if (a.displayOrder > b.displayOrder) return 1
          if (a.displayOrder < b.displayOrder) return -1
          return 0
        })
      }
      // update our store
      self.$store.commit('setFaqs', self.faqs)
      // clear selections
      this.$refs.faqsTable.deselectAll()
    },
    selectFaq(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedFaqsIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedFaqsIds.push(aRow.id)
        })
      } else {
        this.selectedFaqsIds = []
      }
    },
    toggleFaqRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.faqs ) {
          let selectedFaq = undefined
          this.faqs.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedFaq = aResult
            }
          })
          if ( selectedFaq ) {
            this.selectedFaqsIds.push(selectedFaq.id)
            let selectedRows = []
            this.selectedFaqsIds.forEach(function(anId) {
              self.faqs.forEach(function(aFaq) {
                if ( aFaq.id == anId ) {
                  selectedRows.push(aFaq)
                }
              })
            })
            this.$refs.faqsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedFaqsIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedFaqsIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.faqsTable.deselectAll()
        this.selectedFaqsIds.forEach(function(anId) {
          self.faqs.forEach(function(aFaq) {
            if ( aFaq.id == anId ) {
              selectedRows.push(aFaq)
            }
          })
        })
        this.$refs.faqsTable.selectRows(selectedRows)
      }
    },
    removeSelectedFaqs() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedFaqsIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.faqs.length; ++i) {
            if ( self.faqs[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.faqs.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeFaqs(this.selectedFaqsIds).then(response => {
          if (response && response.status == 'ok') {
            // we just need to update the store now
            this.$store.commit('setFaqs', self.faqs)
            let faqsRemovedToast = new Toast(document.getElementById('faqsRemovedToast'))
            faqsRemovedToast.show()
          }
        })

        // cleanup
        this.selectedFaqsIds = []
        this.$refs.faqsTable.deselectAll()
      }
    },
    refreshFaqs() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingFaqs = true
      this.$refs.faqsTable.deselectAll()
      let self = this
      api.getFaqs().then(response => {
        if (response && response.faqs && response.faqs.length > 0 ) {
          // all good
          self.faqs = response.faqs
          this.$store.commit('setFaqs', response.faqs)

          if ( self.faqs ) {
            // alphabetize them!
            self.faqs.sort(function(a,b) {
              if (a.displayOrder > b.displayOrder) return 1
              if (a.displayOrder < b.displayOrder) return -1
              return 0
            })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setFaqs', [])
          self.faqs = []
        }
        this.refreshingFaqs = false
      })
    }
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>