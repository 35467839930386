<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="studentEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="student.id">Edit</span><span v-else>Add</span> Student</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="student.id">edit this</span><span v-else>add a new</span> student, fill out the profile form and tap the <span v-if="student.id">Update</span><span v-else>Add</span> button. You may also add, drop and modify course enrollments in the Student Courses section.</div>

            <div class="container" v-if="updatingStudent">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while the student is <span v-if="student.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid mb-3" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an unknown error <span v-if="student.id">updating</span><span v-else>adding</span> this student. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div v-if="!updatingStudent">
                <div class="card">
                  <div class="card-header">
                    <strong>Student Profile</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="firstName" name="firstName" v-model="student.firstName" v-bind:class="{'is-valid' : validation.firstName, 'is-invalid': validation.firstName == false}" autocomplete="one-time-code">
                            <label for="firstName">First Name</label>
                            <div class="invalid-feedback">Please provide student first name.</div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="lastName" name="lastName" v-model="student.lastName" v-bind:class="{'is-valid' : validation.lastName, 'is-invalid': validation.lastName == false}" autocomplete="one-time-code">
                            <label for="lastName">Last Name</label>
                            <div class="invalid-feedback">Please provide student last name.</div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="email" class="form-control" id="email" name="email" v-model="student.email" v-bind:class="{'is-valid' : validation.email, 'is-invalid': validation.email == false}" autocomplete="one-time-code">
                            <label for="email">Email</label>
                            <div class="invalid-feedback">Please provide student email.</div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="asaMemberId" name="firstName" v-model="student.asaMemberId" autocomplete="one-time-code">
                            <label for="asaMemberId">ASA Member ID</label>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <select class="form-control form-control-sm" id="includeInReminders" v-model="student.reminderEmailsOn" name="includeInReminders">
                              <option value="please_select" disabled selected>Please Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            <label for="includeInReminders">Reminder Emails Enabled?</label>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="phone" name="phone" v-model="student.phone" v-bind:class="{'is-valid' : validation.phone, 'is-invalid': validation.phone == false}" autocomplete="one-time-code">
                            <label for="email">Phone</label>
                            <div class="invalid-feedback">Please provide student phone.</div>
                          </div>
                        </div>

                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-4">
                          <table>
                            <tr><td>Registered:</td><td>&nbsp;</td><td v-html="printDate(student.createdAt)"></td></tr>
                            <tr><td>Last Updated:</td><td>&nbsp;</td><td v-html="printDate(student.updatedAt)"></td></tr>
                          </table>
                        </div>
                        <div class="col-lg-4">
                          <div v-if="student.sailingResumeId !== undefined">
                            <span class="fs-433"><a :href="'https://sailingresume.io/'+student.sailingResumeId" target="_blank"><fai :icon="['far', 'file-alt']"></fai></a>&nbsp;<a :href="'https://sailingresume.io/'+student.sailingResumeId" target="_blank">View Sailing Resume</a></span>
                          </div>
                          <div v-else>
                            <span><fai class="text-danger" icon="times-circle"/>&nbsp;No Sailing Resume</span>
                          </div>

                        </div>
                        <div class="col-lg-4">
                          <div>Viewed Getting Started: <span v-if="student.viewedGettingStarted"><fai class="text-success" :icon="['far', 'check-circle']"></fai>&nbsp;Yes</span><span v-else><fai class="text-danger" icon="times-circle"/>&nbsp;No</span></div>
                        </div>
                      </div>

                      <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-primary" :class="{'disabled': updatingStudent}" @click="crupStudent"><span v-if="student.id">Update</span><span v-else>Add</span> Student Profile</button>

                    </div>
                  </div>
                </div>


                <div class="card mt-3" v-if="student.id">
                  <div class="card-header">
                    <strong>Student Courses</strong>
                    <div class="float-end" v-if="!lookingUpEnrollments"><button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addCourse()"><fai icon="plus"/>&nbsp;Add Course Registration</button></div>
                  </div>
                  <div class="card-body">

                    <div class="container" v-if="lookingUpEnrollments">
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;"><fai icon="spinner" spin/> Looking up student courses...</div>
                        </div>
                      </div>
                    </div>

                    <div class="container" v-if="resettingEnrollment">
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;"><fai icon="spinner" spin/> Resetting course progress...</div>
                        </div>
                      </div>
                    </div>

                    <div class="container" v-if="droppingEnrollment">
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;"><fai icon="spinner" spin/> Dropping course...</div>
                        </div>
                      </div>
                    </div>

                    <div class="container" v-if="updatingEnrollmentScore">
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;"><fai icon="spinner" spin/> Updating score for course...</div>
                        </div>
                      </div>
                    </div>

                    <div class="container" v-if="updatingEnrollmentDates">
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;"><fai icon="spinner" spin/> Updating dates for course...</div>
                        </div>
                      </div>
                    </div>

                    <div class="container" v-if="updatingEnrollmentInstructor">
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;"><fai icon="spinner" spin/> Updating instructor for course...</div>
                        </div>
                      </div>
                    </div>

                    <div class="container-fluid" v-if="errorUpdatingScore">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tx-submit-spinner">
                            <div class="alert alert-danger" role="alert">
                              There was an <span v-if="errorMessage === undefined">unknown </span>error during processing of score input.
                              <div v-if="errorMessage" class="fw-bold">Error: {{errorMessage}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container-fluid" v-if="errorUpdatingDates">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tx-submit-spinner">
                            <div class="alert alert-danger" role="alert">
                              There was an <span v-if="errorMessage === undefined">unknown </span>error during changing dates for course.
                              <div v-if="errorMessage" class="fw-bold">Error: {{errorMessage}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container-fluid" v-if="errorUpdatingInstructor">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tx-submit-spinner">
                            <div class="alert alert-danger" role="alert">
                              There was an <span v-if="errorMessage === undefined">unknown </span>error during changing instructor for course.
                              <div v-if="errorMessage" class="fw-bold">Error: {{errorMessage}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container-fluid" v-if="!lookingUpEnrollments && !resettingEnrollment && !droppingEnrollment && !updatingEnrollmentScore && !updatingEnrollmentDates && !updatingEnrollmentInstructor">

                      <div class="row" v-if="enrollments == undefined || enrollments.length <= 0">
                        <div class="col-12 text-center">
                          <div class="tx-submit-spinner" style="color: #666;">Student is not registered for any courses.</div>
                        </div>
                      </div>

                      <div class="accordion-item" v-for="(enrollment) in enrollments" :key="enrollment.id">
                        <h2 class="accordion-header">
                          <button class="accordion-button" :class="enrollment.id === currentExpandedEnrollmentId ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="'#enrollment-'+enrollment.id" aria-expanded="true" :aria-controls="'enrollment-'+enrollment.id" @click="setCurrentlyExpandedEnrollment(enrollment)">
                            {{enrollment.courseTitle}}&nbsp;<span class="text-dark">(<span v-html="formatCourseDates(enrollment.startDate,enrollment.endDate)"></span>)</span>
                          </button>

                          <div class="progress" role="progressbar" aria-label="tasks percent complete" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="height: 3px;">
                            <div class="progress-bar bg-success" :style="'width:'+ enrollment.percentComplete+'%;'"></div>
                          </div>
                        </h2>
                        <div :id="'enrollment-'+enrollment.id" class="accordion-collapse collapse" :class="enrollment.id === currentExpandedEnrollmentId ? 'show' : ''" data-bs-parent="#portalHome">
                          <div class="accordion-body">

                            <div class="mt-0"><strong>Registered On</strong>: <span v-html="printDateShort(enrollment.createdAt)"></span> | <strong>Last Reminder Sent</strong>: <span v-html="printDateShort(enrollment.lastReminderSentOn)"></span> | <strong>Instructor</strong>: <span v-if="enrollment.instructor">{{enrollment.instructor.firstName}} {{enrollment.instructor.lastName}} <fai class="text-danger charterRodeBtn" @click="clearInstructor(enrollment)" icon="times-circle"/></span><span v-else>Unassigned</span> | <strong>Score</strong>: <span v-if="enrollment.score">{{enrollment.score}} <fai class="text-danger charterRodeBtn" @click="clearScore(enrollment)" icon="times-circle"/></span><span v-else>Not Recorded</span> </div>

                            <div class="mt-3">
                              <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
                                <button type="button" class="btn btn-outline-primary btn-sm me-2 mt-2" @click="changeScore(enrollment)">Enter Score</button>
                                <button type="button" class="btn btn-outline-dark btn-sm me-2 mt-2" :disabled="enrollment.score" @click="changeDates(enrollment)">Change Dates</button>
                                <button type="button" class="btn btn-outline-dark btn-sm me-2 mt-2" :disabled="enrollment.score" @click="changeInstructor(enrollment)">Change Instructor</button>
                                <button type="button" class="btn btn-outline-dark btn-sm me-2 mt-2" :disabled="enrollment.score" @click="resetCourseProgress(enrollment)">Reset Progress</button>
                                <button type="button" class="btn btn-outline-danger btn-sm me-2 mt-2" :disabled="enrollment.score" @click="dropCourse(enrollment)">Drop Course</button>

                                <button type="button" class="btn btn-outline-dark btn-sm me-2 mt-2" :disabled="enrollment.score" @click="sendWelcomeEmail(enrollment)">Send Welcome Email</button>
                                <button type="button" class="btn btn-outline-dark btn-sm me-2 mt-2" :disabled="enrollment.score" @click="sendReminderEmail(enrollment)">Send Reminder Email</button>
                                <button type="button" class="btn btn-outline-dark btn-sm me-2 mt-2" :disabled="enrollment.score" @click="sendInstructorReassignedEmail(enrollment)">Send Instructor Reassigned Email</button>

                                <button v-if="user && user.superAdmin && student.sailingResumeId == undefined" type="button" class="btn btn-outline-dark btn-sm me-2" @click="setSailingResume(enrollment)">Set Sailing Resume</button>

                              </div>

                              <table class="table table-sm table-responsive-lg">
                                <thead>
                                  <tr>
                                    <th style="width: 10px;">&nbsp;</th>
                                    <th>Task</th>
                                    <th>Completed On</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="task in enrollment.tasks" :key="task.id">
                                    <td :class="task.complete ? 'text-success' : ''" style="width: 10px;"><fai :icon="task.complete ? [ 'far', 'check-circle'] : [ 'far', 'circle']"></fai></td>
                                    <td :class="task.complete ? 'text-success' : ''">{{task.title}}</td>
                                    <td :class="task.complete ? 'text-success' : ''"><span v-if="task.complete" v-html="printDateShort(task.completionDate)"></span><span v-else>Incomplete</span></td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-center mt-3" v-if="enrollments.length > 0">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
                          <div style="display:inline-block;margin:0 10px;">
                            <div class="d-grid gap-2">
                              <button id="updateProfileButton" class="btn btn-lg btn-outline-primary text-uppercase" @click="addCourse()">Register Student For Another Course</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="studentEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Student Successfully <span v-if="student.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The student has been <span v-if="student.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

  <dateSelectorModal
      v-on:dateSelected="dateSelected"
      ref="dateSelectorModal"></dateSelectorModal>

  <instructorSelectorModal
      v-on:instructorSelected="instructorSelected"
      ref="instructorSelectorModal"></instructorSelectorModal>


</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'StudentEditorModal',
  emits: ['studentUpdated', 'registerStudent'],
  data() {
    return {
      user:  this.$store.state.admin,
      updatingStudent: false,
      enrollments: undefined,
      lookingUpEnrollments: false,
      resettingEnrollment: false,
      droppingEnrollment: false,
      updatingEnrollmentScore: false,
      updatingEnrollmentDates: false,
      updatingEnrollmentInstructor: false,
      currentExpandedEnrollmentId: undefined,
      student: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        asaMemberId: undefined,
        reminderEmailsOn: 'true'
      },
      validation : {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        asaMemberId: undefined
      },
      errorUpdating: false,
      errorUpdatingScore: false,
      errorUpdatingDates: false,
      errorUpdatingInstructor: false,
      errorMessage: undefined
    }
  },
  mounted() {

  },
  methods: {
    activateModal(studentToEdit) {
      this.studentEditorModal = new Modal(document.getElementById('studentEditorModal'))
      let studentEditorEl = document.getElementById('studentEditorModal')
      let self = this
      self.$store.state.activeModal = self.studentEditorModal
      studentEditorEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
        // self.$emit('modalClose')
      })
      self.studentEditorModal.toggle()

      if ( studentToEdit !== undefined && studentToEdit.id !== undefined ) {
        this.student = studentToEdit
        this.student.reminderEmailsOn = this.student.reminderEmailsOn ? 'true' : 'false'
        this.enrollments = undefined
      } else {
        this.student = {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          phone: undefined,
          asaMemberId: undefined,
          reminderEmailsOn: 'true'
        }
        this.enrollments = undefined
      }
      this.validation = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        asaMemberId: undefined
      }
      this.errorUpdating = false
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      this.resettingEnrollment = false
      this.droppingEnrollment = false
      this.updatingEnrollmentScore = false
      this.updatingEnrollmentDates = false
      this.updatingEnrollmentInstructor = false

      if ( this.student && this.student.id && this.enrollments == undefined ) {
        this.refreshEnrollments()
      }
    },
    crupStudent() {
      // validation!
      this.updatingStudent = true
      this.errorUpdating = false
      this.errorMessage = false
      // reset validation state
      let isFormValid = true
      this.validation = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        asaMemberId: undefined
      }

      // validate the forms entries!
      if ( this.student.firstName === undefined || this.student.firstName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.firstName = false
      } else {
        this.validation.firstName = true
      }
      if ( this.student.lastName === undefined || this.student.lastName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.lastName = false
      } else {
        this.validation.lastName = true
      }
      if ( this.student.email === undefined || this.student.email.trim().length <= 0 || !this.$validateEmail(this.student.email) ) {
        isFormValid = false
        this.validation.email = false
      } else {
        this.validation.email = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingStudent = false
        return
      }

      // send the validated input to the back-end....
      let theStudent = {
        id: this.student.id !== undefined ? this.student.id : undefined,
        firstName:this.student.firstName,
        lastName:this.student.lastName,
        email:this.student.email,
        phone:this.student.phone,
        asaMemberId: this.student.asaMemberId,
        reminderEmailsOn: this.student.reminderEmailsOn === "true"
      }
      let self = this
      api.crupStudent(theStudent).then(response => {
        if (response && response.student !== undefined ) {
          // all good - show the Toaster and cleanup....
          let studentEditorToast = new Toast(document.getElementById('studentEditorToast'))
          studentEditorToast.show()
          // close the modal - maybe don't do this for students?
          // self.studentEditorModal.hide()
          self.$emit('studentUpdated',response.student)
          self.updatingStudent = false
          this.validation = {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            phone: undefined,
            asaMemberId: undefined
          }
        } else {
          // unknown error - what to do?
          self.updatingStudent = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
    printDate(aJsTimeStamp) {
      return new Date(aJsTimeStamp).toLocaleDateString() + ' @ '+ new Date(aJsTimeStamp).toLocaleTimeString()
    },
    printDateShort(aJsTimeStamp) {
      return new Date(aJsTimeStamp).toLocaleDateString()
    },
    formatCourseDates(startDate, endDate) {
      // trip.date is in format YYYY-MM-DD
      const monthsOfYear = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let tripStart = startDate.split('-')
      let tripEnd = endDate.split('-')
      if ( tripStart[0] === tripEnd[0] ) { // years match
        if (tripStart[1] === tripEnd[1] ) { // months match
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+tripEnd[2]+', '+tripStart[0]
        } else { // months mismatch
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripStart[0]
        }
      } else { // years mismatch...
        return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+', '+tripStart[0]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripEnd[0]
      }
    },
    setCurrentlyExpandedEnrollment(anEnrollment) {
      window.document.activeElement.blur()
      if ( this.currentExpandedEnrollmentId === anEnrollment.id ) {
        this.currentExpandedEnrollmentId = ''
      } else {
        this.currentExpandedEnrollmentId = anEnrollment.id
      }
    },
    resetCourseProgress(anEnrollment) {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      if ( confirm("Are you sure you want to reset all progress for this course?") ) {
        let self = this
        self.resettingEnrollment = true
        api.resetEnrollment(this.student.id, anEnrollment.id).then(response => {
          if (response && response.status === 'ok') {
            // all good!
            self.refreshEnrollments()
          }
          self.resettingEnrollment = false
        })
      }
    },
    addCourse() {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      // this could be the same as register student workflow, just an edit version
      // with the student info locked looking for course and course start date
      this.$emit('registerStudent',JSON.parse(JSON.stringify(this.student)))
    },
    changeDates(enrollment) {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      // this could be the same as register student workflow, just an edit version
      // with the student info and course info locked looking for course start date
      this.enrollmentUpdatingDate = enrollment
      this.$refs.dateSelectorModal.activateModal()
    },
    dateSelected(theDate) {
      // console.log('Changing dates for enrollment:'+this.enrollmentUpdatingDate.id +' to:'+theDate)
      // send dates to back-end
      let self = this
      self.updatingEnrollmentDates = true
      api.setStartDateForEnrollment(this.student.id, this.enrollmentUpdatingDate.id, theDate).then(response => {
        if (response && response.status === 'ok') {
          // all good!
          self.refreshEnrollments()
          self.$emit('studentUpdated',self.student)
        } else {
          // error
          if ( response ) {
            self.errorMessage = response
          }
          self.errorUpdatingDates = true
        }
        self.updatingEnrollmentDates = false
      })
      // clear out
      this.enrollmentUpdatingDate = undefined
    },
    clearInstructor(anEnrollment) {
      if ( confirm("Are you sure you want to clear the instructor assignment for this course?") ) {
        let self = this
        self.updatingEnrollmentInstructor = true
        api.setInstructorForEnrollment(this.student.id, anEnrollment.id, 'unassigned').then(response => {
          if (response && response.status === 'ok') {
            // all good!
            self.refreshEnrollments()
            self.$emit('studentUpdated',self.student)
          } else {
            // error
            if ( response ) {
              self.errorMessage = response
            }
            self.errorUpdatingInstructor = true
          }
          self.updatingEnrollmentInstructor = false
        })
      }
    },
    changeInstructor(enrollment) {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      this.enrollmentUpdatingInstructor = enrollment
      this.$refs.instructorSelectorModal.activateModal()
    },
    instructorSelected(anInstructorId) {
      console.log('Changing instructor for enrollment:'+this.enrollmentUpdatingInstructor.id +' to:'+anInstructorId)
      // send instructor to back-end
      let self = this
      self.updatingEnrollmentInstructor = true
      api.setInstructorForEnrollment(this.student.id, this.enrollmentUpdatingInstructor.id, anInstructorId).then(response => {
        if (response && response.status === 'ok') {
          // all good!
          self.refreshEnrollments()
          self.$emit('studentUpdated',self.student)
        } else {
          // error
          if ( response ) {
            self.errorMessage = response
          }
          self.errorUpdatingInstructor = true
        }
        self.updatingEnrollmentInstructor = false
      })
      // clear out
      this.enrollmentUpdatingInstructor = undefined
    },
    clearScore(anEnrollment) {
      if ( confirm("Are you sure you want to clear the student's score for this course?") ) {
        let self = this
        self.updatingEnrollmentScore = true
        api.setScoreForEnrollment(this.student.id, anEnrollment.id, -1).then(response => {
          if (response && response.status === 'ok') {
            // all good!
            self.refreshEnrollments()
            self.$emit('studentUpdated',self.student)
          } else {
            // error
            if ( response ) {
              self.errorMessage = response
            }
            self.errorUpdatingScore = true
          }
          self.updatingEnrollmentScore = false
        })
      }
    },
    changeScore(enrollment) {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      this.errorMessage = undefined
      let scoreInput = prompt("Enter student score between 0 - 100. For example, 92.5")
      if ( scoreInput !== undefined && scoreInput !== null ) {
        try {
          let score = parseFloat(scoreInput)
          if (score >= -1.00 && score <= 100.00) {
            // send score to back-end
            let self = this
            self.updatingEnrollmentScore = true
            api.setScoreForEnrollment(this.student.id, enrollment.id, score).then(response => {
              if (response && response.status === 'ok') {
                // all good!
                self.refreshEnrollments()
                self.$emit('studentUpdated',self.student)
              } else {
                // error
                if ( response ) {
                  self.errorMessage = response
                }
                self.errorUpdatingScore = true
              }
              self.updatingEnrollmentScore = false
            })
          } else {
            // invalid score
            alert("Invalid score entered:" + scoreInput + ". Try again.")
          }
        } catch (err) {
          alert("Invalid score entered:" + scoreInput + ". Try again.")
        }
      }
    },
    dropCourse(anEnrollment) {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      if ( confirm("Are you sure you want to permanently remove this course from this student's profile?") ) {
        let self = this
        self.droppingEnrollment = true
        api.dropEnrollment(this.student.id, anEnrollment.id).then(response => {
          if (response && response.status === 'ok') {
            // all good!
            self.refreshEnrollments()
            self.$emit('studentUpdated',self.student)
          }
          self.droppingEnrollment = false
        })
      }
    },
    setSailingResume(anEnrollment) {
      window.document.activeElement.blur()
      let sailingResumeId = prompt("Enter student Sailing Resume Id from Charter Rode:")
      if ( sailingResumeId !== undefined && sailingResumeId !== null ) {
        console.log('setting sailing resume for enrollment:'+anEnrollment.id +' with sailingResumeId:'+sailingResumeId)
        let self = this
        api.setSailingResumeIdFor(anEnrollment.id, sailingResumeId).then(response => {
          if (response && response.status === 'ok') {
            // all good!
            self.refreshEnrollments()
            self.$emit('studentUpdated',self.student)
          }
        })
      }
    },
    sendInstructorReassignedEmail(anEnrollment) {
      window.document.activeElement.blur()
      let self = this
      api.sendInstructorReassignedEmail(anEnrollment.id).then(response => {
        if (response && response.status === 'ok') {
          // all good!
          self.refreshEnrollments()
          self.$emit('studentUpdated',self.student)
        }
      })
    },
    sendReminderEmail(anEnrollment) {
      window.document.activeElement.blur()
      let self = this
      api.sendReminderEmail(anEnrollment.id).then(response => {
        if (response && response.status === 'ok') {
          // all good!
          self.refreshEnrollments()
          self.$emit('studentUpdated',self.student)
        }
      })
    },
    sendWelcomeEmail(anEnrollment) {
      window.document.activeElement.blur()
      let self = this
      api.sendWelcomeEmail(anEnrollment.id).then(response => {
        if (response && response.status === 'ok') {
          // all good!
          self.refreshEnrollments()
          self.$emit('studentUpdated',self.student)
        }
      })
    },
    refreshEnrollments() {
      window.document.activeElement.blur()
      this.errorUpdatingScore = false
      this.errorUpdatingDates = false
      this.errorUpdatingInstructor = false
      let self = this
      self.lookingUpEnrollments = true
      api.getStudentEnrollments(this.student.id).then(response => {
        if (response && response.enrollments !== undefined ) {
          self.enrollments = response.enrollments
          // most recent will always be on top
          if ( self.enrollments.length > 0 ) {
            self.currentExpandedEnrollmentId = self.enrollments[0].id
          }
        }
        self.lookingUpEnrollments = false
      })
    }
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>