<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="calendarEventEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="calendarEvent.id">Edit</span><span v-else>Add</span> Event</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="calendarEvent.id">edit this</span><span v-else>add a new</span> event, fill out the form and tap the <span v-if="calendarEvent.id">Save</span><span v-else>Add</span> button below.</div>

            <div class="container" v-if="updatingCalendarEvent">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while your event is <span v-if="calendarEvent.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid mb-3" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an unknown error <span v-if="calendarEvent.id">updating</span><span v-else>adding</span> this event. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container-fluid" v-if="!updatingCalendarEvent">

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-4">
                        <v-date-picker mode="date" v-model="calendarEvent.startDate" :popover="{ visibility: 'click' }" :min-date="minDateForCourse" :max-date="maxDateForCourse">
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="form-floating">
                              <input type="text" class="form-control charterRodeBtn" id="calEvent-startDate" name="startDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.startDate, 'is-invalid': validation.startDate == false}">
                              <label for="calEvent-startDate">Start Date</label>
                              <div class="invalid-feedback">Please provide start date.</div>
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <select class="form-control form-control-sm" id="type" v-model="calendarEvent.type" name="type" v-bind:class="{'is-valid' : validation.type, 'is-invalid': validation.type == false}">
                          <option value="please_select" disabled selected>Please Select</option>
                          <option value="course">Course</option>
                          <option value="community">Community Sail</option>
                          <option value="al_departure">Adventure Learning Departure</option>
                          <option value="al_return">Adventure Learning Return</option>
                        </select>
                        <label for="type">Type of Event?</label>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <select class="form-control form-control-sm" id="courseId" v-model="calendarEvent.courseId" name="type" v-bind:class="{'is-valid' : validation.courseId, 'is-invalid': validation.courseId == false}">
                          <option value="please_select" disabled selected>Please Select</option>
                          <option v-for="item in courses" :key="item.id" :value="item.id">{{item.courseTitle}}</option>
                        </select>
                        <label for="courseId">Which Course?</label>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="number" class="form-control" id="spotsAvailable" name="spotsAvailable" v-model="calendarEvent.spotsAvailable" v-bind:class="{'is-valid' : validation.lastName, 'is-invalid': validation.spotsAvailable == false}" autocomplete="one-time-code">
                        <label for="spotsAvailable"># Spots Available</label>
                        <div class="invalid-feedback">Please provide # spots.</div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="number" class="form-control" id="price" name="price" v-model="calendarEvent.price" v-bind:class="{'is-valid' : validation.price, 'is-invalid': validation.price == false}" autocomplete="one-time-code">
                        <label for="price">Price</label>
                        <div class="invalid-feedback">Please provide price.</div>
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="fees" name="fees" v-model="calendarEvent.fees" v-bind:class="{'is-valid' : validation.fees, 'is-invalid': validation.fees == false}" autocomplete="one-time-code">
                        <label for="fees">Fees</label>
                        <div class="invalid-feedback">Please provide fees.</div>
                      </div>
                    </div>
                  </div>


                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="backToBackDays" v-model="calendarEvent.backToBackDays" name="backToBackDays" v-bind:class="{'is-valid' : validation.backToBackDays, 'is-invalid': validation.backToBackDays == false}">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label for="backToBackDays">Back to back days?</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="backToBackWeeks" v-model="calendarEvent.backToBackWeeks" name="backToBackWeeks" v-bind:class="{'is-valid' : validation.backToBackWeeks, 'is-invalid': validation.backToBackWeeks == false}">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label for="backToBackWeeks">Back to back weeks?</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="singleDay" v-model="calendarEvent.singleDay" name="singleDay" v-bind:class="{'is-valid' : validation.singleDay, 'is-invalid': validation.singleDay == false}">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label for="singleDay">Single day event?</label>
                    </div>
                  </div>

                </div>

              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="crupCalendarEvent"><span v-if="calendarEvent.id">Save</span><span v-else>Add</span> Event</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="calendarEventEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Event Successfully <span v-if="calendarEvent.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The event has been <span v-if="calendarEvent.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'CalendarEventEditorModal',
  emits: ['calendarEventUpdated'],
  data() {
    return {
      user:  this.$store.state.admin,
      courses: this.$store.state.courses ? this.$store.state.courses : [],
      updatingCalendarEvent: false,
      calendarEvent: {
        startDate: this.startDate = new Date(),
        spotsAvailable: 3,
        price: 725,
        fees: 59,
        type: 'course',
        courseId: undefined,
        backToBackDays: "true",
        backToBackWeeks: "false",
        singleDay: "false"
      },
      validation : {
        startDate: undefined,
        spotsAvailable: undefined,
        price: undefined,
        fees: undefined,
        type: undefined,
        courseId: undefined,
        backToBackDays:undefined,
        backToBackWeeks: undefined,
        singleDay: undefined
      },
      errorUpdating: false
    }
  },
  mounted() {

  },
  methods: {
    activateModal(calendarEventToEdit) {
      this.calendarEventEditorModal = new Modal(document.getElementById('calendarEventEditorModal'))
      let calendarEventEditorEl = document.getElementById('calendarEventEditorModal')
      let self = this
      self.$store.state.activeModal = self.calendarEventEditorModal
      calendarEventEditorEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
      })
      self.calendarEventEditorModal.toggle()

      if ( calendarEventToEdit !== undefined && ((calendarEventToEdit.id !== undefined) || calendarEventToEdit.isCopy === true) ) {
        this.calendarEvent = calendarEventToEdit
        this.calendarEvent.backToBackDays = this.calendarEvent.backToBackDays ? 'true' : 'false'
        this.calendarEvent.backToBackWeeks = this.calendarEvent.backToBackWeeks ? 'true' : 'false'
        this.calendarEvent.singleDay = this.calendarEvent.singleDay ? 'true' : 'false'
        this.calendarEvent.startDate = new Date(this.calendarEvent.startDate + " 12:00:00")
        this.calendarEvent.isCopy = undefined
      } else {
        this.calendarEvent = {
          startDate: new Date(),
          spotsAvailable: 3,
          price: 725,
          fees: 59,
          type: 'course',
          courseId: undefined,
          backToBackDays: "true",
          backToBackWeeks: "false",
          singleDay: "false"
        }
      }
      this.validation = {
        startDate: undefined,
        spotsAvailable: undefined,
        price: undefined,
        fees: undefined,
        type:undefined,
        courseId: undefined,
        backToBackDays:undefined,
        backToBackWeeks: undefined,
        singleDay: undefined
      }
      this.errorUpdating = false

      // do we need to fetch the available courses?
      if ( this.$store.state.coursesUpdated == false ) {
        // first time to load this screen so go grab them if there are any...
        this.doRefreshCourses()
      }
    },
    crupCalendarEvent() {
      // validation!
      this.updatingCalendarEvent = true
      this.errorUpdating = false
      // reset validation state
      let isFormValid = true
      this.validation = {
        question: undefined,
        answer: undefined
      }

      // validate the forms entries!
      if ( this.calendarEvent.startDate === undefined ) {
        isFormValid = false
        this.validation.startDate = false
      } else {
        this.validation.startDate = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingCalendarEvent = false
        return
      }

      // send the validated input to the back-end....
      let offset = this.calendarEvent.startDate.getTimezoneOffset()
      let theCalendarEvent = {
        id: this.calendarEvent.id !== undefined ? this.calendarEvent.id : undefined,
        startDate: new Date(this.calendarEvent.startDate.getTime() - (offset*60*1000)).toISOString().split('T')[0],
        spotsAvailable: parseInt(this.calendarEvent.spotsAvailable),
        price: this.calendarEvent.price,
        fees: this.calendarEvent.fees,
        type: this.calendarEvent.type,
        courseId: this.calendarEvent.courseId,
        backToBackDays: this.calendarEvent.backToBackDays === "true",
        backToBackWeeks: this.calendarEvent.backToBackWeeks === "true",
        singleDay: this.calendarEvent.singleDay === "true"
      }
      let self = this
      api.crupCalendarEvent(theCalendarEvent).then(response => {
        if (response && response.calendarEvent !== undefined ) {
          // all good - show the Toaster and cleanup....
          let calendarEventEditorToast = new Toast(document.getElementById('calendarEventEditorToast'))
          calendarEventEditorToast.show()
          // close the modal
          self.calendarEventEditorModal.hide()
          self.$emit('calendarEventUpdated',response.calendarEvent)
          self.updatingCalendarEvent = false
        } else {
          // unknown error - what to do?
          self.updatingCalendarEvent = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
    refreshCourses() {
      window.document.activeElement.blur()
      this.doRefreshCourses()
    },
    doRefreshCourses() {
      // get the current set of
      this.refreshingCourses = true
      let self = this
      api.getCourses().then(response => {
        if (response && response.courses && response.courses.length > 0 ) {
          // all good
          self.courses = response.courses
          this.$store.commit('setCourses', response.courses)

          if ( self.courses ) {
            // alphabetize them!
            self.courses.sort(function(a,b) {
              if (a.courseTitle.toLowerCase() > b.courseTitle.toLowerCase()) return 1
              if (a.courseTitle.toLowerCase() < b.courseTitle.toLowerCase()) return -1
              return 0
            })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setCourses', [])
          self.courses = []
        }
        this.refreshingCourses = false
      })
    }
  },
  computed: {
    minDateForCourse() {
      const oneYearDayMillis = 365 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // set the min date allowed
      todayDate.setTime(todayDate.getTime() - oneYearDayMillis)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    },
    maxDateForCourse() {
      const oneYearDayMillis = 365 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // set the max date allowed
      todayDate.setTime(todayDate.getTime() + oneYearDayMillis)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    }
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>