<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="envelope" /> Emails</h4>
          <div class="charterRodeHelpText">Setup and manage email templates that are sent to your students. Tap the name of an email template to update it.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addEmail">Add Email</button>
            <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedEmailIds.length <= 0" @click="removeSelectedEmails">Remove Selected</button>
            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshEmails" :disabled="refreshingEmails"><span v-show="refreshingEmails"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingEmails">Refresh Emails</span></button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="emails.length === 0 || emails.length > 1">{{emails.length}} Emails</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{emails.length}} Email</div>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <v-table
                :data="emails"
                ref="emailsTable"
                class="table"
                selectionMode="multiple"
                selectedClass="table-primary"
                @stateChanged="selectEmail($event.selectedRows)">
              <template #head>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <v-th sortKey="subject">Subject</v-th>
                  <v-th sortKey="emailType">Type</v-th>
                  <v-th sortKey="offsetDays" class="text-end">Offset Days</v-th>
                  <v-th sortKey="offsetType" class="text-end">Offset Type</v-th>
                  <v-th sortKey="enabledForAllCourses" class="text-end">All Courses</v-th>
                  <v-th sortKey="active" class="text-end">Active</v-th>
                </tr>
              </template>
              <template #body="{ rows }">
                <tr v-show="rows.length == 0 || refreshingEmails">
                  <td>&nbsp;</td>
                  <td colspan="6" style="text-align: center"><span v-if="refreshingEmails"><fai icon="spinner" spin/> Refreshing emails...</span><span v-else>Ahoy there, what no emails? <a class="charterRodeLink" @click="addEmail">Let's add your first email!</a></span></td>
                </tr>
                <v-tr v-for="row in rows" :key="row.id" :row="row">
                  <td v-if="!refreshingEmails" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="emailId" v-bind:value="row.id" v-model="selectedEmailIds" @click="toggleEmailRow"></td>
                  <td v-if="!refreshingEmails"><a @click="editEmail(row)">{{row.subject}}</a></td>
                  <td v-if="!refreshingEmails" class="text-capitalize">{{row.emailType}}</td>
                  <td v-if="!refreshingEmails" class="text-end">{{row.offsetDays}}</td>
                  <td v-if="!refreshingEmails" class="text-end" style="text-transform: capitalize;">{{row.offsetType}}</td>
                  <td v-if="!refreshingEmails" class="text-end"><span v-if="row.enabledForAllCourses == true">Yes</span><span v-else>No</span></td>
                  <td v-if="!refreshingEmails" class="text-end"><span v-if="row.active == true">Yes</span><span v-else>No</span></td>
                </v-tr>
              </template>
            </v-table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="emailsRemovedToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Removed</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected emails have been removed.</div>
      </div>
    </div>
  </teleport>

  <emailEditorModal
      v-on:emailUpdated="emailUpdated"
      ref="emailEditorModal"></emailEditorModal>

</template>

<script>

import api from "@/api/api";
import {Toast} from "bootstrap";

export default {
  name: 'Settings',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      emails: this.$store.state.emails ? this.$store.state.emails : [],
      selectedEmailIds: [],
      refreshingEmails: false
    }
  },
  mounted() {
    if ( this.$store.state.emailsUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshEmails()
    }
  },
  methods: {
    addEmail() {
      this.$refs.emailEditorModal.activateModal()
    },
    editEmail(anEmail) {
      // deep clone
      let emailToEdit = JSON.parse(JSON.stringify(anEmail))
      this.$refs.emailEditorModal.activateModal(emailToEdit)
    },
    emailUpdated(theEmail) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.emails.forEach(function(anEmail) {
        if ( theEmail.id === anEmail.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        self.emails.splice(foundAt,1)
        self.emails.push(theEmail)
      } else {
        self.emails.push(theEmail)
      }
      // update our store
      self.$store.commit('setEmails', self.emails)
      // clear selections
      this.$refs.emailsTable.deselectAll()
    },
    selectEmail(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedEmailIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedEmailIds.push(aRow.id)
        })
      } else {
        this.selectedEmailIds = []
      }
    },
    toggleEmailRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.emails ) {
          let selectedEmail = undefined
          this.emails.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedEmail = aResult
            }
          })
          if ( selectedEmail ) {
            this.selectedEmailIds.push(selectedEmail.id)
            let selectedRows = []
            this.selectedEmailIds.forEach(function(anId) {
              self.emails.forEach(function(anEmail) {
                if ( anEmail.id == anId ) {
                  selectedRows.push(anEmail)
                }
              })
            })
            this.$refs.emailsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedEmailIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedEmailIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.emailsTable.deselectAll()
        this.selectedEmailIds.forEach(function(anId) {
          self.emails.forEach(function(anEmail) {
            if ( anEmail.id == anId ) {
              selectedRows.push(anEmail)
            }
          })
        })
        this.$refs.emailsTable.selectRows(selectedRows)
      }
    },
    removeSelectedEmails() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedEmailIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.emails.length; ++i) {
            if ( self.emails[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.emails.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeEmails(this.selectedEmailIds).then(response => {
          if (response && response.status == 'ok') {
            // we just need to update the store now
            this.$store.commit('setEmails', self.emails)
            let emailsRemovedToast = new Toast(document.getElementById('emailsRemovedToast'))
            emailsRemovedToast.show()
          }
        })

        // cleanup
        this.selectedEmailIds = []
        this.$refs.emailsTable.deselectAll()
      }
    },
    refreshEmails() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingEmails = true
      this.$refs.emailsTable.deselectAll()
      let self = this
      api.getEmails().then(response => {
        if (response && response.emails && response.emails.length > 0 ) {
          // all good
          self.emails = response.emails
          this.$store.commit('setEmails', response.emails)

          if ( self.emails ) {
            // alphabetize them!
            self.emails.sort(function(a,b) {
              if (a.subject.toLowerCase() > b.subject.toLowerCase()) return 1
              if (a.subject.toLowerCase() < b.subject.toLowerCase()) return -1
              return 0
            })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setEmails', [])
          self.emails = []
        }
        this.refreshingEmails = false
      })
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>
