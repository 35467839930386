<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="schoolEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="school.id">Edit</span><span v-else>Add</span> School</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="school.id">edit this</span><span v-else>add a new</span> school, fill out the form and tap the <span v-if="school.id">Save</span><span v-else>Add</span> button below.</div>

            <div class="container" v-if="updatingSchool">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while your school is <span v-if="school.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid mb-3" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an unknown error <span v-if="school.id">updating</span><span v-else>adding</span> this school. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div class="container-fluid" v-if="!updatingSchool">

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="schoolName" name="schoolName" v-model="school.schoolName" v-bind:class="{'is-valid' : validation.schoolName, 'is-invalid': validation.schoolName == false}" autocomplete="one-time-code">
                      <label for="schoolName">School Name</label>
                      <div class="invalid-feedback">Please provide name.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="email" class="form-control" id="schoolEmail" name="schoolEmail" v-model="school.schoolEmail" v-bind:class="{'is-valid' : validation.schoolEmail, 'is-invalid': validation.schoolEmail == false}" autocomplete="one-time-code">
                      <label for="schoolEmail">School Email</label>
                      <div class="invalid-feedback">Please provide email.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="schoolPhone" name="schoolPhone" v-model="school.schoolPhone" v-bind:class="{'is-valid' : validation.schoolPhone, 'is-invalid': validation.schoolPhone == false}" autocomplete="one-time-code">
                      <label for="schoolPhone">School Phone</label>
                      <div class="invalid-feedback">Please provide main phone number.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="location" name="location" v-model="school.location" v-bind:class="{'is-valid' : validation.location, 'is-invalid': validation.location == false}" autocomplete="one-time-code">
                      <label for="location">School Location</label>
                      <div class="invalid-feedback">Please provide a location.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="crVoucherCode" name="crVoucherCode" v-model="school.crVoucherCode" v-bind:class="{'is-valid' : validation.crVoucherCode, 'is-invalid': validation.crVoucherCode == false}" autocomplete="one-time-code">
                      <label for="crVoucherCode">Voucher Code</label>
                      <div class="invalid-feedback">Please provide a voucher code.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="selfRegistrationKey" name="selfRegistrationKey" v-model="school.selfRegistrationKey" v-bind:class="{'is-valid' : validation.selfRegistrationKey, 'is-invalid': validation.selfRegistrationKey == false}" autocomplete="one-time-code">
                      <label for="selfRegistrationKey">Self Registration Key</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="logoUrl" name="logoUrl" v-model="school.logoUrl" v-bind:class="{'is-valid' : validation.logoUrl, 'is-invalid': validation.logoUrl == false}" autocomplete="one-time-code">
                      <label for="logoUrl">Logo Url</label>
                      <div class="invalid-feedback">Please provide url to logo.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="websiteUrl" name="websiteUrl" v-model="school.websiteUrl" v-bind:class="{'is-valid' : validation.websiteUrl, 'is-invalid': validation.websiteUrl == false}" autocomplete="one-time-code">
                      <label for="websiteUrl">Website Url</label>
                      <div class="invalid-feedback">Please provide url to website.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="windyAppSpotId" name="windyAppSpotId" v-model="school.windyAppSpotId" v-bind:class="{'is-valid' : validation.windyAppSpotId, 'is-invalid': validation.windyAppSpotId == false}" autocomplete="one-time-code">
                      <label for="websiteUrl">Windy.APP Spot ID</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="welcomeBccEmail" name="welcomeBccEmail" v-model="school.welcomeBccEmail" v-bind:class="{'is-valid' : validation.welcomeBccEmail, 'is-invalid': validation.welcomeBccEmail == false}" autocomplete="one-time-code">
                      <label for="welcomeBccEmail">Welcome Bcc</label>
                      <div class="invalid-feedback">Please provide email.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="reminderBccEmail" name="reminderBccEmail" v-model="school.reminderBccEmail" v-bind:class="{'is-valid' : validation.reminderBccEmail, 'is-invalid': validation.reminderBccEmail == false}" autocomplete="one-time-code">
                      <label for="reminderBccEmail">Reminder Email Bcc</label>
                      <div class="invalid-feedback">Please provide email.</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="customBccEmail" name="customBccEmail" v-model="school.customBccEmail" v-bind:class="{'is-valid' : validation.customBccEmail, 'is-invalid': validation.customBccEmail == false}" autocomplete="one-time-code">
                      <label for="customBccEmail">Custom Email Bcc</label>
                      <div class="invalid-feedback">Please provide email.</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="active" v-model="school.active" name="active">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label for="active">Active?</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="aboutUsDescription" name="aboutUsDescription" v-model="school.aboutUsDescription" v-bind:class="{'is-valid' : validation.aboutUsDescription, 'is-invalid': validation.aboutUsDescription == false}" style="height: 300px"></textarea>
                      <label for="aboutUsDescription">About Us</label>
                      <div id="aboutUsHelpBlock" class="form-text">Two to three sentence for the about us section.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="bio" name="bio" v-model="school.noEnrollmentsMessage" v-bind:class="{'is-valid' : validation.noEnrollmentsMessage, 'is-invalid': validation.noEnrollmentsMessage == false}" style="height: 160px"></textarea>
                      <label for="noEnrollmentsMessage">No Enrollment Message</label>
                      <div id="noEnrollmentsMessageHelpBlock" class="form-text">Short message for when a student has no enrollments.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="gettingStartedSignature" name="gettingStartedSignature" v-model="school.gettingStartedSignature" v-bind:class="{'is-valid' : validation.gettingStartedSignature, 'is-invalid': validation.gettingStartedSignature == false}" style="height: 160px"></textarea>
                      <label for="gettingStartedSignature">Getting Started Signature</label>
                      <div id="gettingStartedSignatureHelpBlock" class="form-text">Signature block for the Getting Started section.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="bio" name="bio" v-model="school.welcomeEmail" v-bind:class="{'is-valid' : validation.welcomeEmail, 'is-invalid': validation.welcomeEmail == false}" style="height: 500px"></textarea>
                      <label for="welcomeEmail">Welcome Email</label>
                      <div id="welcomeEmailHelpBlock" class="form-text">HTML for the welcome email.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="reminderEmail" name="reminderEmail" v-model="school.reminderEmail" v-bind:class="{'is-valid' : validation.reminderEmail, 'is-invalid': validation.reminderEmail == false}" style="height: 500px"></textarea>
                      <label for="reminderEmail">Reminder Email</label>
                      <div id="reminderEmailHelpBlock" class="form-text">HTML for the reminder emails.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="aboutUsDescription" name="aboutUsDescription" v-model="school.links" v-bind:class="{'is-valid' : validation.links, 'is-invalid': validation.links == false}" style="height: 550px"></textarea>
                      <label for="aboutUsDescription">Links</label>
                      <div id="aboutUsHelpBlock" class="form-text">JSON string of array of links for school.</div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="crupSchool"><span v-if="school.id">Save</span><span v-else>Add</span> School</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="schoolEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">School Successfully <span v-if="school.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The school has been <span v-if="school.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'SchoolEditorModal',
  emits: ['schoolUpdated'],
  data() {
    return {
      user:  this.$store.state.admin,
      updatingSchool: false,
      school: {
        schoolName: undefined,
        schoolEmail: undefined,
        schoolPhone: undefined,
        location: undefined,
        aboutUsDescription: undefined,
        logoUrl: undefined,
        websiteUrl: undefined,
        windyAppSpotId: undefined,
        crVoucherCode: undefined,
        welcomeBccEmail: undefined,
        reminderBccEmail: undefined,
        customBccEmail: undefined,
        active: true,
        links: undefined,
        faqs: undefined,
        noEnrollmentsMessage: undefined,
        gettingStartedSignature: undefined,
        welcomeEmail: undefined,
        reminderEmail: undefined,
        selfRegistrationKey: undefined
      },
      validation : {
        schoolName: undefined,
        schoolEmail: undefined,
        schoolPhone: undefined,
        location: undefined,
        aboutUsDescription: undefined,
        logoUrl: undefined,
        websiteUrl: undefined,
        windyAppSpotId: undefined,
        crVoucherCode: undefined,
        welcomeBccEmail: undefined,
        reminderBccEmail: undefined,
        customBccEmail: undefined,
        links: undefined,
        faqs: undefined,
        noEnrollmentsMessage: undefined,
        gettingStartedSignature: undefined,
        welcomeEmail: undefined,
        reminderEmail: undefined,
        selfRegistrationKey: undefined
      },
      errorUpdating: false
    }
  },
  mounted() {

  },
  methods: {
    activateModal(schoolToEdit) {
      this.schoolEditorModal = new Modal(document.getElementById('schoolEditorModal'))
      let schoolEditorEl = document.getElementById('schoolEditorModal')
      let self = this
      self.$store.state.activeModal = self.schoolEditorModal
      schoolEditorEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
        self.$emit('modalClose')
      })
      self.schoolEditorModal.toggle()

      if ( schoolToEdit !== undefined && schoolToEdit.id !== undefined ) {
        this.school = schoolToEdit
        this.school.links = this.school.links ?  JSON.stringify(this.school.links, null, 2) : undefined
        this.school.active = this.school.active ? 'true' : 'false'
      } else {
        this.school = {
          schoolName: undefined,
          schoolEmail: undefined,
          schoolPhone: undefined,
          location: undefined,
          aboutUsDescription: undefined,
          logoUrl: undefined,
          websiteUrl: undefined,
          windyAppSpotId: undefined,
          crVoucherCode: undefined,
          welcomeBccEmail: undefined,
          reminderBccEmail: undefined,
          customBccEmail: undefined,
          active: true,
          links: undefined,
          noEnrollmentsMessage: undefined,
          gettingStartedSignature: undefined,
          welcomeEmail: undefined,
          reminderEmail: undefined,
          selfRegistrationKey: undefined
        }

      }
      this.validation = {
        schoolName: undefined,
        schoolEmail: undefined,
        schoolPhone: undefined,
        location: undefined,
        aboutUsDescription: undefined,
        logoUrl: undefined,
        websiteUrl: undefined,
        windyAppSpotId: undefined,
        crVoucherCode: undefined,
        welcomeBccEmail: undefined,
        reminderBccEmail: undefined,
        customBccEmail: undefined,
        links: undefined,
        noEnrollmentsMessage: undefined,
        gettingStartedSignature: undefined,
        welcomeEmail: undefined,
        reminderEmail: undefined,
        selfRegistrationKey: undefined
      }
      this.errorUpdating = false
    },
    crupSchool() {
      // validation!
      this.updatingSchool = true
      this.errorUpdating = false
      // reset validation state
      let isFormValid = true
      this.validation = {
        schoolName: undefined,
        schoolEmail: undefined,
        schoolPhone: undefined,
        location: undefined,
        aboutUsDescription: undefined,
        logoUrl: undefined,
        websiteUrl: undefined,
        windyAppSpotId: undefined,
        crVoucherCode: undefined,
        welcomeBccEmail: undefined,
        reminderBccEmail: undefined,
        customBccEmail: undefined,
        links: undefined,
        faqs: undefined,
        noEnrollmentsMessage: undefined,
        gettingStartedSignature: undefined,
        welcomeEmail: undefined,
        reminderEmail: undefined,
        selfRegistrationKey: undefined
      }

      // validate the forms entries!
      if ( this.school.schoolName === undefined || this.school.schoolName.trim().length <= 0 ) {
        isFormValid = false
        this.validation.schoolName = false
      } else {
        this.validation.schoolName = true
      }
      if ( this.school.schoolEmail === undefined || this.school.schoolEmail.trim().length <= 0 || !this.$validateEmail(this.school.schoolEmail) ) {
        isFormValid = false
        this.validation.schoolEmail = false
      } else {
        this.validation.schoolEmail = true
      }
      if ( this.school.schoolPhone === undefined || this.school.schoolPhone.trim().length <= 0 ) {
        isFormValid = false
        this.validation.schoolPhone = false
      } else {
        this.validation.schoolPhone = true
      }
      if ( this.school.location === undefined || this.school.location.trim().length <= 0 ) {
        isFormValid = false
        this.validation.location = false
      } else {
        this.validation.location = true
      }
      if ( this.school.websiteUrl === undefined || this.school.websiteUrl.trim().length <= 0 ) {
        isFormValid = false
        this.validation.websiteUrl = false
      } else {
        this.validation.lowebsiteUrlcation = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingSchool = false
        return
      }

      // send the validated input to the back-end....
      let theSchool = {
        id: this.school.id !== undefined ? this.school.id : undefined,
        schoolName: this.school.schoolName,
        schoolEmail: this.school.schoolEmail,
        schoolPhone: this.school.schoolPhone,
        location: this.school.location,
        aboutUsDescription: this.school.aboutUsDescription ? this.school.aboutUsDescription : undefined,
        logoUrl: this.school.logoUrl ? this.school.logoUrl : undefined,
        websiteUrl: this.school.websiteUrl ? this.school.websiteUrl : undefined,
        windyAppSpotId: this.school.windyAppSpotId ? this.school.windyAppSpotId : undefined,
        crVoucherCode: this.school.crVoucherCode ? this.school.crVoucherCode : undefined,
        welcomeBccEmail: this.school.welcomeBccEmail ? this.school.welcomeBccEmail : undefined,
        reminderBccEmail: this.school.reminderBccEmail ? this.school.reminderBccEmail : undefined,
        customBccEmail: this.school.customBccEmail ? this.school.customBccEmail : undefined,
        active: this.school.active === "true",
        links: this.school.links ? JSON.parse(this.school.links) : undefined,
        noEnrollmentsMessage: this.school.noEnrollmentsMessage ? this.school.noEnrollmentsMessage : undefined,
        gettingStartedSignature: this.school.gettingStartedSignature ? this.school.gettingStartedSignature : undefined,
        welcomeEmail: this.school.welcomeEmail ? this.school.welcomeEmail : undefined,
        reminderEmail: this.school.reminderEmail ? this.school.reminderEmail : undefined
      }
      let self = this
      api.crupSchool(theSchool).then(response => {
        if (response && response.school !== undefined ) {
          // all good - show the Toaster and cleanup....
          let schoolEditorToast = new Toast(document.getElementById('schoolEditorToast'))
          schoolEditorToast.show()
          // close the modal
          self.schoolEditorModal.hide()
          self.$emit('schoolUpdated',response.school)
          self.updatingSchool = false
        } else {
          // unknown error - what to do?
          self.updatingSchool = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>