<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="school" /> Schools</h4>
          <div class="charterRodeHelpText">Setup and manage schools. Tap the name of a school to update their info.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="addSchool">Add School</button>
<!--            <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedSchoolIds.length <= 0" @click="removeSelectedSchools">Remove Selected</button>-->
            <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshSchools" :disabled="refreshingSchools"><span v-show="refreshingSchools"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingSchools">Refresh Schools</span></button>
            <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="schools.length === 0 || schools.length > 1">{{schools.length}} Schools</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{schools.length}} School</div>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl">
            <v-table
                :data="schools"
                ref="schoolsTable"
                class="table"
                selectionMode="multiple"
                selectedClass="table-primary"
                @stateChanged="selectSchool($event.selectedRows)">
              <template #head>
                <tr>
                  <th style="width:5px;">&nbsp;</th>
                  <v-th sortKey="schoolName">Name</v-th>
                  <v-th sortKey="schoolEmail">Email</v-th>
                  <v-th sortKey="schoolPhone">Phone</v-th>
                  <th>&nbsp;</th>
                </tr>
              </template>
              <template #body="{ rows }">
                <tr v-show="rows.length == 0 || refreshingSchools">
                  <td>&nbsp;</td>
                  <td colspan="4" style="text-align: center"><span v-if="refreshingSchools"><fai icon="spinner" spin/> Refreshing schools...</span><span v-else>Ahoy there, what no schools? <a class="charterRodeLink" @click="addSchool">Let's add your first school!</a></span></td>
                </tr>
                <v-tr v-for="row in rows" :key="row.id" :row="row">
                  <td v-if="!refreshingSchools" style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="schoolId" v-bind:value="row.id" v-model="selectedSchoolIds" @click="toggleSchoolRow"></td>
                  <td v-if="!refreshingSchools"><span v-if="row.id === '91d6ae3f-c95a-4030-af9d-5b1f3367a169'" class="fw-bold">DEMO: </span><a @click="editSchool(row)">{{row.schoolName}}</a></td>
                  <td v-if="!refreshingSchools">{{row.schoolEmail}}</td>
                  <td v-if="!refreshingSchools">{{row.schoolPhone}}</td>
                  <td v-if="!refreshingSchools"><a :href="row.websiteUrl" target="_blank">Website</a> | <a :href="'https://www.google.com/maps/search/'+row.location" target="_blank">Map</a> | <span v-if="user.schoolId === row.id">Sticky</span><a v-else href="#" @click.prevent="setAsSticky(row.id)">Set Sticky</a></td>
                </v-tr>
              </template>
            </v-table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="schoolsRemovedToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Removed</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected schools have been removed.</div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="schoolsStickyToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Successfully Set</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The selected school has been successfully set as sticky.</div>
      </div>
    </div>
  </teleport>

  <schoolEditorModal
      v-on:schoolUpdated="schoolUpdated"
      ref="schoolEditorModal"></schoolEditorModal>

</template>

<script>
import api from "@/api/api"
import {Toast} from "bootstrap";

export default {
  name: 'Schools',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      schools: this.$store.state.schools ? this.$store.state.schools : [],
      selectedSchoolIds: [],
      refreshingSchools: false
    }
  },
  mounted() {
    if ( this.$store.state.schoolsUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshSchools()
    }
  },
  methods: {
    addSchool() {
      this.$refs.schoolEditorModal.activateModal()
    },
    editSchool(aSchool) {
      // deep clone
      let schoolToEdit = JSON.parse(JSON.stringify(aSchool))
      this.$refs.schoolEditorModal.activateModal(schoolToEdit)
    },
    setAsSticky(schoolId) {
      api.setStickySchool(schoolId).then(response => {
        if (response && response.status === 'ok' ) {
          let schoolsStickyToast = new Toast(document.getElementById('schoolsStickyToast'))
          schoolsStickyToast.show()
          // clear the local cache of items....
          this.$store.commit('setStickySchool', schoolId)
        }
      })
    },
    schoolUpdated(theSchool) {
      let self = this
      let foundAt = -1
      let currIndex = 0
      self.schools.forEach(function(aSchool) {
        if ( theSchool.id === aSchool.id ) {
          foundAt = currIndex
        }
        ++currIndex
      })
      if ( foundAt >= 0 ) {
        // have to splice and then re-add....
        self.schools.splice(foundAt,1)
        self.schools.push(theSchool)
      } else {
        self.schools.push(theSchool)
      }
      // update our store
      self.$store.commit('setSchools', self.schools)
      // clear selections
      this.$refs.schoolsTable.deselectAll()
    },
    selectSchool(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedSchoolIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedSchoolIds.push(aRow.id)
        })
      } else {
        this.selectedSchoolIds = []
      }
    },
    toggleSchoolRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.schools ) {
          let selectedSchool = undefined
          this.schools.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedSchool = aResult
            }
          })
          if ( selectedSchool ) {
            this.selectedSchoolIds.push(selectedSchool.id)
            let selectedRows = []
            this.selectedSchoolIds.forEach(function(anId) {
              self.schools.forEach(function(aSchool) {
                if ( aSchool.id == anId ) {
                  selectedRows.push(aSchool)
                }
              })
            })
            this.$refs.schoolsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedSchoolIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedSchoolIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.schoolsTable.deselectAll()
        this.selectedSchoolIds.forEach(function(anId) {
          self.schools.forEach(function(aSchool) {
            if ( aSchool.id == anId ) {
              selectedRows.push(aSchool)
            }
          })
        })
        this.$refs.schoolsTable.selectRows(selectedRows)
      }
    },
    removeSelectedSchools() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedSchoolIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.schools.length; ++i) {
            if ( self.schools[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.schools.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeSchools(this.selectedSchoolIds).then(response => {
          if (response && response.status == 'ok') {
            // we just need to update the store now
            this.$store.commit('setSchools', self.schools)
            let schoolsRemovedToast = new Toast(document.getElementById('schoolsRemovedToast'))
            schoolsRemovedToast.show()

          }
        })

        // cleanup
        this.selectedSchoolIds = []
        this.$refs.schoolsTable.deselectAll()
      }
    },
    refreshSchools() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingSchools = true
      this.$refs.schoolsTable.deselectAll()
      let self = this
      api.getSchools().then(response => {
        if (response && response.schools && response.schools.length > 0 ) {
          // all good
          self.schools = response.schools
          this.$store.commit('setSchools', response.schools)

          if ( self.schools ) {
            // alphabetize them!
            self.schools.sort(function(a,b) {
              if (a.name > b.name) return 1
              if (a.name < b.name) return -1
              return 0
            })
          }
        } else {
          // I guess there is nothing!
          this.$store.commit('setSchools', [])
          self.schools = []
        }
        this.refreshingSchools = false
      })
    }
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>
