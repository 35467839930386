<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="courseEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel"><span v-if="course.id">Edit</span><span v-else>Add</span> Course</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-4 charterRodeHelpText">To <span v-if="course.id">edit this</span><span v-else>add a new</span> course, fill out the form and tap the <span v-if="course.id">Save</span><span v-else>Add</span> button below.</div>

            <div class="container" v-if="updatingCourse">
              <div class="row">
                <div class="col-12 text-center">
                  <div class="tx-submit-spinner mt-lg-5 fa-2x" style="color: #666;"><fai icon="spinner" spin/> Please wait while your course is <span v-if="course.id">updated</span><span v-else>added</span> ...</div>
                </div>
              </div>
            </div>

            <div class="container-fluid mb-3" v-if="errorUpdating">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner">
                    <div class="alert alert-danger" role="alert">
                      There was an unknown error <span v-if="course.id">updating</span><span v-else>adding</span> this course. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

              <div v-if="!updatingCourse">
                <div class="card">
                  <div class="card-header">
                    <strong>Course Info</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-8">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="courseTitle" name="courseTitle" v-model="course.courseTitle" v-bind:class="{'is-valid' : validation.courseTitle, 'is-invalid': validation.courseTitle == false}">
                            <label for="courseTitle">Course Title</label>
                            <div class="invalid-feedback">Please provide the course title.</div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <select class="form-control form-control-sm" id="includeInReminders" v-model="course.includeInReminders" name="includeInReminders" v-bind:class="{'is-valid' : validation.includeInReminders, 'is-invalid': validation.includeInReminders == false}">
                              <option value="please_select" disabled selected>Please Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            <label for="includeInReminders">Include in Reminder Emails?</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="courseTitleShort" name="courseTitleShort" v-model="course.courseTitleShort" v-bind:class="{'is-valid' : validation.courseTitleShort, 'is-invalid': validation.courseTitleShort == false}">
                            <label for="courseTitle">Course Title Short</label>
                            <div class="invalid-feedback">Please provide a short course title.</div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <input type="number" class="form-control" id="courseDurationDays" name="courseDurationDays" v-model="course.courseDurationDays" v-bind:class="{'is-valid' : validation.courseDurationDays, 'is-invalid': validation.courseDurationDays == false}">
                            <label for="courseDurationDays">Course Duration (days)</label>
                            <div class="invalid-feedback">Please provide the course title.</div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-floating mb-4">
                            <select class="form-control form-control-sm" id="includeInReminders" v-model="course.hasOnlineCourse" name="hasOnlineCourse" v-bind:class="{'is-valid' : validation.hasOnlineCourse, 'is-invalid': validation.hasOnlineCourse == false}">
                              <option value="please_select" disabled selected>Please Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            <label for="includeInReminders">Has online prep course?</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-floating mb-4">
                            <textarea class="form-control" id="courseOverview" name="courseOverview" v-model="course.courseOverview" v-bind:class="{'is-valid' : validation.courseOverview, 'is-invalid': validation.courseOverview == false}" style="height: 100px"></textarea>
                            <label for="courseOverview">Course Overview</label>
                            <div class="invalid-feedback">Please provide the course overview.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-2">
                  <div class="card-header">
                    <strong>Textbook Info</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="textbook.title" name="textbook.title" v-model="course.textbook.title" v-bind:class="{'is-valid' : validation.textbook.title, 'is-invalid': validation.textbook.title == false}">
                            <label for="textbook.title">Textbook Title</label>
                            <div class="invalid-feedback">Please provide the course title.</div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-floating mb-4">
                            <input type="text" class="form-control" id="textbook.imageSrc" name="textbook.imageSrc" v-model="course.textbook.imageSrc" v-bind:class="{'is-valid' : validation.textbook.imageSrc, 'is-invalid': validation.textbook.imageSrc == false}">
                            <label for="textbook.imageSrc">Textbook Image Url</label>
                            <div class="invalid-feedback">Please provide the textbook title.</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-floating mb-4">
                            <textarea class="form-control" id="textbook.description" name="textbook.description" v-model="course.textbook.description" v-bind:class="{'is-valid' : validation.textbook.description, 'is-invalid': validation.textbook.description == false}" style="height: 160px"></textarea>
                            <label for="textbook.description">Textbook Description</label>
                            <div class="invalid-feedback">Please provide the textbook description.</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-floating mb-4">
                            <textarea class="form-control" id="textbook.purchaseOptions" name="textbook.description" v-model="course.textbook.purchaseOptions" v-bind:class="{'is-valid' : validation.textbook.purchaseOptions, 'is-invalid': validation.textbook.purchaseOptions == false}" style="height: 100px"></textarea>
                            <label for="textbook.purchaseOptions">Textbook Purchase Options</label>
                            <div class="invalid-feedback">Please provide the textbook purchase options.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-2">
                  <div class="card-header">
                    <strong>Student Tasks</strong>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid" >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-floating mb-4">
                            <textarea class="form-control" id="tasks" name="tasks" v-model="course.tasks" v-bind:class="{'is-valid' : validation.tasks, 'is-invalid': validation.tasks == false}" style="height: 3000px"></textarea>
                            <label for="tasks">Tasks JSON</label>
                            <div class="invalid-feedback">Please provide the textbook purchase options.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="crupCourse"><span v-if="course.id">Save</span><span v-else>Add</span> Course</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="courseEditorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Course Successfully <span v-if="course.id">Updated</span><span v-else>Added</span>!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">The course has been <span v-if="course.id">updated</span><span v-else>added</span>.</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "@/api/api";

export default {
  name: 'CourseEditorModal',
  emits: ['courseUpdated'],
  data() {
    return {
      user:  this.$store.state.admin,
      updatingCourse: false,
      course: {
        courseTitle: undefined,
        courseTitleShort: undefined,
        courseOverview: undefined,
        textbook: {
          imageSrc: undefined,
          title: undefined,
          description: undefined,
          purchaseOptions: undefined
        },
        tasks: undefined,
        includeInReminders: 'true',
        hasOnlineCourse: 'false',
        courseDurationDays: undefined
      },
      validation : {
        courseTitle: undefined,
        courseTitleShort: undefined,
        courseOverview: undefined,
        textbook: {
          imageSrc: undefined,
          title: undefined,
          description: undefined,
          purchaseOptions: undefined
        },
        tasks: undefined,
        courseDurationDays: undefined
      },
      errorUpdating: false
    }
  },
  mounted() {

  },
  methods: {
    activateModal(courseToEdit) {
      this.courseEditorModal = new Modal(document.getElementById('courseEditorModal'))
      let courseEditorEl = document.getElementById('courseEditorModal')
      let self = this
      self.$store.state.activeModal = self.courseEditorModal
      courseEditorEl.addEventListener('hidden.bs.modal', function () {
        self.$store.state.activeModal = undefined
        self.$emit('modalClose')
      })
      self.courseEditorModal.toggle()

      if ( courseToEdit !== undefined && courseToEdit.id !== undefined ) {
        this.course = courseToEdit
        this.course.includeInReminders = this.course.includeInReminders ? 'true' : 'false'
        this.course.tasks = this.course.tasks ? JSON.stringify(this.course.tasks,null, 2) : undefined
      } else {
        this.course = {
          courseTitle: undefined,
          courseTitleShort: undefined,
          courseOverview: undefined,
          textbook: {
            imageSrc: undefined,
            title: undefined,
            description: undefined,
            purchaseOptions: undefined
          },
          tasks: undefined,
          includeInReminders: 'true',
          hasOnlineCourse: 'false',
          courseDurationDays: undefined
        }

      }
      this.validation = {
        courseTitle: undefined,
        courseTitleShort: undefined,
        courseOverview: undefined,
        textbook: {
          imageSrc: undefined,
          title: undefined,
          description: undefined,
          purchaseOptions: undefined
        },
        tasks: undefined,
        courseDurationDays: undefined
      }
      this.errorUpdating = false
    },
    crupCourse() {
      // validation!
      this.updatingCourse = true
      this.errorUpdating = false
      // reset validation state
      let isFormValid = true
      this.validation = {
        courseTitle: undefined,
        courseTitleShort: undefined,
        courseOverview: undefined,
        textbook: {
          imageSrc: undefined,
          title: undefined,
          description: undefined,
          purchaseOptions: undefined
        },
        tasks: undefined,
        courseDurationDays: undefined
      }

      // validate the forms entries!
      if ( this.course.courseTitle === undefined || this.course.courseTitle.trim().length <= 0 ) {
        isFormValid = false
        this.validation.courseTitle = false
      } else {
        this.validation.courseTitle = true
      }
      if ( this.course.courseTitleShort === undefined || this.course.courseTitleShort.trim().length <= 0 ) {
        isFormValid = false
        this.validation.courseTitleShort = false
      } else {
        this.validation.courseTitleShort = true
      }
      if ( this.course.courseOverview === undefined || this.course.courseOverview.trim().length <= 0 ) {
        isFormValid = false
        this.validation.courseOverview = false
      } else {
        this.validation.courseOverview = true
      }

      // do we have valid entries?
      if ( !isFormValid ) {
        this.updatingCourse = false
        return
      }

      // send the validated input to the back-end....
      let theCourse = {
        id: this.course.id !== undefined ? this.course.id : undefined,
        courseTitle: this.course.courseTitle,
        courseTitleShort: this.course.courseTitleShort,
        courseOverview: this.course.courseOverview,
        textbook: this.course.textbook,
        tasks: this.course.tasks ? JSON.parse(this.course.tasks) : undefined,
        includeInReminders: this.course.includeInReminders === "true",
        hasOnlineCourse: this.course.hasOnlineCourse === "true",
        courseDurationDays: this.course.courseDurationDays
      }
      let self = this
      api.crupCourse(theCourse).then(response => {
        if (response && response.course !== undefined ) {
          // all good - show the Toaster and cleanup....
          let courseEditorToast = new Toast(document.getElementById('courseEditorToast'))
          courseEditorToast.show()
          // close the modal
          self.courseEditorModal.hide()
          self.$emit('courseUpdated',response.course)
          self.updatingCourse = false
        } else {
          // unknown error - what to do?
          self.updatingCourse = false
          // provide on-screen error message to try again later...?
          self.errorUpdating = true
        }
      })
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>