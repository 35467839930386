<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4><fai icon="calendar" /> Schedule</h4>
          <div class="charterRodeHelpText">Review how students are doing in completing tasks for upcoming courses. To register a student for a course, tap the Register Student button. Tap the name of the student to view the details for a specific student, including the ability to assign a specific instructor for a course. To change the time window, select a start and end date and tap the Refresh Schedule button.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" style="color: #666;">
          <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
              <button type="button" class="btn btn-outline-primary btn-sm me-2" @click="registerStudent">Register Student</button>
              <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="refreshScheduleItems" :disabled="refreshingScheduleItems"><span v-show="refreshingScheduleItems"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingScheduleItems">Refresh Schedule</span></button>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <v-date-picker mode="date" v-model="startDate" :popover="{ visibility: 'click' }" class="d-inline">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-floating">
                <input style="height: 45px!important;padding-top: 1rem!important;padding-left: 0.5rem!important;" type="text" class="form-control form-control-sm charterRodeBtn" id="sched-startDate" name="courseStartDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.startDate, 'is-invalid': validation.courseStartDate == false}">
                <label for="sched-startDate" style="padding:.5rem 0.5rem!important;">Start Date</label>
              </div>
            </template>
          </v-date-picker>
        </div>
        <div class="col-6">
          <v-date-picker mode="date" v-model="endDate" :popover="{ visibility: 'click' }" class="d-inline">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-floating">
                <input style="height: 45px!important;padding-top: 1rem!important;padding-left: 0.5rem!important;" type="text" class="form-control form-control-sm charterRodeBtn" id="sched-EndDate" name="courseStartDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.endDate, 'is-invalid': validation.courseStartDate == false}">
                <label for="sched-EndDate" style="padding:.5rem 0.5rem!important;">End Date</label>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-show="items.length == 0 || refreshingScheduleItems" class="mt-3 fs-5 text-center">
            <span v-if="refreshingScheduleItems"><fai icon="spinner" spin/> Refreshing schedule...</span><span v-else>No students registered for the time period selected.</span>
          </div>
          <div style="margin-top:15px;" class="table-responsive-xxl" v-if="!refreshingScheduleItems && items.length > 0">
            <table>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <table class="table table-responsive table-sm">
                    <thead>
                    <tr>
                      <th :colspan="item.cols.length-1" class="fw-bold fs-4">{{item.title}}</th>
                      <th><div class="float-end" style="color: #888;font-size: 14px;font-weight: normal;" v-if="item.totalStudents === 0 || item.totalStudents > 1">{{item.totalStudents}} students</div><div class="float-end" style="color: #888;font-size: 14px;font-weight: normal;" v-else>{{item.totalStudents}} student</div></th>
                    </tr>
                    <tr>
                      <th v-for="col in item.cols" :key="col.id" :style="col.taskCol ? 'text-align: center' : ''" class="table-light">{{col.colName}}</th>
                    </tr>
                    </thead>
                    <tbody v-for="section in item.sections" :key="section.id">
                    <tr>
                      <td :colspan="item.cols.length" class="table-primary fw-bold fs-5 text-center">{{section.title}} - <span v-if="section.rows.length === 0 || section.rows.length > 1">{{section.rows.length}} students</span><span v-else>{{section.rows.length}} student</span></td>
                    </tr>
                    <tr v-for="row in section.rows" :key="row.id">
                      <td v-for="col in item.cols" :key="col.id" :style="col.taskCol ? 'text-align: center' : 'white-space:nowrap;color:#666;'" :class="col.taskCol ? isTaskComplete(col,row) ? 'table-success' : 'table-danger' : 'table-light'"><span v-if="col.taskCol"><fai :class="isTaskComplete(col,row) ? 'text-success' : ''" :icon="isTaskComplete(col,row) ? [ 'far', 'check-circle'] : [ 'far', 'circle',]" class="fs-5"></fai></span><span v-else v-html="getDataFor(col,row)" @click="editStudent(row.student)" class="charterRodeBtn"></span></td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>

          </div>

        </div>
      </div>

    </div>
  </div>

  <studentEditorModal
      v-on:studentUpdated="studentUpdated"
      v-on:registerStudent="registerStudentForCourse"
      ref="studentEditorModal"></studentEditorModal>

  <studentRegistrationModal
      v-on:studentRegistered="studentRegistered"
      ref="studentRegistrationModal"></studentRegistrationModal>


</template>

<script>

import api from "@/api/api"

export default {
  name: 'Schedule',
  data() {
    return {
      user: this.$store.state.admin ? this.$store.state.admin : {},
      items: this.$store.state.scheduleItems ? this.$store.state.scheduleItems : [],
      startDate: this.$store.state.scheduleStartDate ? this.$store.state.scheduleStartDate : undefined,
      endDate: this.$store.state.scheduleEndDate ? this.$store.state.scheduleEndDate : undefined,
      refreshingScheduleItems: false,
      validation: {
        startDate: undefined,
        endDate: undefined
      }
    }
  },
  mounted() {
    // set default dates to 21 days ago through 90 days from today....
    if ( this.startDate === undefined || this.endDate === undefined ) {
      let now = new Date()
      this.startDate = new Date(new Date().setDate(now.getDate() - 21))
      this.endDate = new Date(new Date().setDate(now.getDate() + 90))
    }

    // now do we need to fetch anything?
    if ( this.$store.state.scheduleItemsUpdated == false ) {
      // first time to load this screen so go grab them if there are any...
      this.refreshScheduleItems()
    }

  },
  methods: {
    registerStudent() {
      // this is a multi-step modal:
      // 1. Add new student or update if email address already exists
      // 2. Optionally select course to enroll into
      // 3. If set #3, then set a start date for the course to confirm the enrollment
      this.$refs.studentRegistrationModal.activateModal()
    },
    registerStudentForCourse(aStudent) {
      this.$refs.studentRegistrationModal.activateModal({student:aStudent})
    },
    studentRegistered() {
      this.refreshScheduleItems()
    },
    editStudent(aStudent) {
      // deep clone
      let studentToEdit = JSON.parse(JSON.stringify(aStudent))
      this.$refs.studentEditorModal.activateModal(studentToEdit)
    },
    studentUpdated() {
      this.refreshScheduleItems()
    },
    refreshScheduleItems() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingScheduleItems = true
      let self = this

      // figure out our dates....
      let offset = this.startDate.getTimezoneOffset()
      let finalStartDate = new Date(this.startDate.getTime() - (offset*60*1000)).toISOString().split('T')[0]
      let finalEndDate = new Date(this.endDate.getTime() - (offset*60*1000)).toISOString().split('T')[0]

      api.getScheduleItems(this.user.schoolId, finalStartDate, finalEndDate).then(response => {
        if (response && response.items && response.items.length > 0 ) {
          // all good
          self.items = response.items
          self.createPivot()
        } else {
          // I guess there is nothing!
          self.$store.commit('setScheduleItems', {scheduleItems:[], startDate: self.startDate, endDate: self.endDate})
          self.items = []
        }
        this.refreshingScheduleItems = false
      })
    },
    createPivot() {
      // the basic structure is a list of objects...
      // let pivotData2 = [{
      //   id: 'courseId',
      //   title: 'ASA 101', // course title
      //   cols: [
      //     {
      //       id: 'courseTaskId?',
      //       colName: 'Student',
      //       colKey: 'name'
      //     },
      //     {
      //       colName: 'Key One',
      //       colKey: 'key1'
      //     }
      //   ],
      //   sections: [{
      //     id: 'instructorGuid',
      //     title: 'Start Date - End Date - Instructor Name',
      //     rows: [{
      //       id: 'enrollment-id',
      //       name: 'Jason Burns',
      //       key1: 'key1'
      //     }],
      //   }]
      // }]

      let pivotData = []

      this.items.sort(function(a,b) {
        let nameA = a.student.lastName+'-'+a.student.firstName
        let nameB = b.student.lastName+'-'+b.student.firstName
        if (nameA > nameB) return 1
        if (nameA < nameB) return -1
        return 0
      })

      for(let i = 0; i < this.items.length; ++i) {
        // first figure out what item we are working within the pivot i.e. which class
        let enrollment = this.items[i]
        let enrollmentSectionKey = enrollment.startDate+'#'+ (enrollment.instructorId === undefined ? 'unassigned' : enrollment.instructorId)
        let pivotItem = undefined
        for(let j = 0; j < pivotData.length; ++j) {
          if ( pivotData[j].id === enrollment.courseId ) {
            pivotItem = pivotData[j]
            break
          }
        }
        if ( pivotItem === undefined ) {
          pivotItem = {
            id: enrollment.courseId,
            title: enrollment.courseTitle,
            cols: [],
            sections:[],
            totalStudents: 0
          }

          pivotItem.cols.push({
            id: 'studentNameCol',
            colName: 'Student',
            colKey: 'name',
            taskCol: false
          })

          // add the new pivotItem
          pivotData.push(pivotItem)
          // maybe sort enrollment.tasks ?
          for(let k = 0; k < enrollment.tasks.length; ++k) {
            pivotItem.cols.push({
              id: enrollment.tasks[k].courseTaskId,
              colName: enrollment.tasks[k].title,
              colKey: enrollment.tasks[k].courseTaskId,
              taskCol: true
            })
          }
        }

        let theSection = undefined
        for (let l = 0; l < pivotItem.sections.length; ++l) {
          if (pivotItem.sections[l].id === enrollmentSectionKey) {
            theSection = pivotItem.sections[l]
            break
          }
        }

        if ( theSection === undefined ) {
          theSection = {
            id: enrollmentSectionKey,
            rows: []
          }
          if ( enrollment.instructorId !== undefined ) {
            theSection.title = this.formatCourseDates(enrollment.startDate,enrollment.endDate) + ' - ' + enrollment.instructor.firstName +' ' + enrollment.instructor.lastName
            theSection.sortKey = enrollment.startDate+'#'+enrollment.instructor.lastName+enrollment.instructor.firstName
          } else {
            theSection.title = this.formatCourseDates(enrollment.startDate,enrollment.endDate) + ' - Instructor Unassigned'
            theSection.sortKey = enrollment.startDate+'#' // want these on top!
          }

          // add the new section
          pivotItem.sections.push(theSection)
        }

        // now finally add the data!
        let theRow = {
          id: enrollment.id
        }
        for(let m = 0; m < pivotItem.cols.length; ++m) {
          if ( pivotItem.cols[m].id === 'studentNameCol' ) {
            theRow.name = enrollment.student.firstName +' '+ enrollment.student.lastName
            theRow.student = enrollment.student
          } else {
            // did the student complete the task?
            let taskComplete = false
            for(let p = 0; p < enrollment.tasks.length; ++p) {
              if ( enrollment.tasks[p].courseTaskId === pivotItem.cols[m].id ) {
                if ( enrollment.tasks[p].complete ) {
                  taskComplete = true
                  break
                }
              }
            }
            theRow[pivotItem.cols[m].colKey] = taskComplete
          }
        }

        theSection.rows.push(theRow)
        pivotItem.totalStudents += 1
      }

      pivotData.sort(function(a,b) {
        if (a.title > b.title) return 1
        if (a.title < b.title) return -1
        return 0
      })
      pivotData.forEach(function(anItem) {
        anItem.sections.sort(function(a,b) {
          if (a.sortKey > b.sortKey) return 1
          if (a.sortKey < b.sortKey) return -1
          return 0
        })
      })

      this.items = pivotData
      this.$store.commit('setScheduleItems', {scheduleItems:pivotData, startDate: this.startDate, endDate: this.endDate})
    },
    getDataFor(col, row) {
      if (row[col.colKey] !== undefined ) {
        return row[col.colKey]
      }
      return "--"
    },
    isTaskComplete(col,row) {
      if (row[col.colKey] !== undefined && row[col.colKey] === true) {
        return true
      }
      return false
    },
    formatCourseDates(startDate, endDate) {
      // trip.date is in format YYYY-MM-DD
      const monthsOfYear = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let tripStart = startDate.split('-')
      let tripEnd = endDate.split('-')
      if ( tripStart[0] === tripEnd[0] ) { // years match
        if (tripStart[1] === tripEnd[1] ) { // months match
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+tripEnd[2]+', '+tripStart[0]
        } else { // months mismatch
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripStart[0]
        }
      } else { // years mismatch...
        return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+', '+tripStart[0]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripEnd[0]
      }
    },
  },
  computed: {

  },
  components: {

  }
}
</script>

<style>


</style>
